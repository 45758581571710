import { FC, useCallback, useRef } from 'react';
import { useScroll } from 'react-use';
import { CarouselArrow } from 'src/components/Carousel/TabsCarousel/CarouselArrow';
import { MetricItem } from './MetricItem';
import { InsightOverviewDataProps } from '../InsightOverviewData';
import { useQuickInsight } from '../QuickInsightContainer';

type QuickInsightMetricsProps = Pick<InsightOverviewDataProps, 'reportMetric' | 'setSelectedMetric' | 'isSurveyType'>;

export const QuickInsightMetrics: FC<QuickInsightMetricsProps> = ({ reportMetric, setSelectedMetric, isSurveyType }) => {
  const { selectedClientSourceData } = useQuickInsight();
  const metrics = selectedClientSourceData?.es_alias.metrics;
  const scrollRef = useRef<HTMLDivElement>(null);
  const scroll = useCallback(
    (direction: 'left' | 'right') => scrollRef.current?.scrollBy({ left: direction === 'left' ? -200 : 200, behavior: 'smooth' }),
    []
  );
  const { x } = useScroll(scrollRef);
  const scrollPercentage = (x / (scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth)) * 100;
  return (
    <div className="relative flex from-white to-transparent">
      {scrollPercentage > 2 && <CarouselArrow side="left" className="w-14" onClick={() => scroll('left')} />}
      <div ref={scrollRef} className="ml-3 flex max-w-[45rem] gap-2 overflow-x-auto scrollbar-hide" data-testid="quick-insight-metrics">
        <MetricItem metric={reportMetric} setSelectedMetric={setSelectedMetric} isSurveyType={isSurveyType} />
        {metrics
          .filter((metric) => metric.name !== reportMetric?.name)
          .map((metric) => {
            return <MetricItem key={metric.name} metric={metric} setSelectedMetric={setSelectedMetric} isSurveyType={isSurveyType} />;
          })}
      </div>
      {scrollPercentage < 98 && <CarouselArrow side="right" className="w-14" onClick={() => scroll('right')} />}
    </div>
  );
};
