import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';

const InboxCardLoader = () => {
  return (
    <>
      <div className="px-6 py-4">
        <div className="flex flex-col gap-2.5">
          <div className="flex items-center justify-between">
            <BarSkeleton width="12%" height={20} />
            <BarSkeleton width="15%" height={20} />
          </div>
          <BarSkeleton width="70%" />
          <BarSkeleton width="35%" />
        </div>
      </div>
      <div className="border-light-6 border-b"></div>
    </>
  );
};

export default InboxCardLoader;
