import * as SwitchPrimitive from '@radix-ui/react-switch';
import { cn } from 'Utils/TailwindUtils';
import { cva } from 'class-variance-authority';
import React from 'react';

interface SwitchProps extends SwitchPrimitive.SwitchProps {
  size?: 'small' | 'large';
}

export const switchCVA = cva(
  [
    'inline-flex shrink-0 items-center rounded-full border-2 border-transparent transition-colors disabled:cursor-not-allowed disabled:bg-grey-100 radix-state-checked:bg-indigo-600 radix-state-unchecked:bg-grey-100',
  ],
  {
    variants: {
      size: {
        small: 'h-4 w-7',
        large: 'h-6 w-11',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  }
);

export const toggleCVA = cva(
  ['pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform radix-state-unchecked:translate-x-0'],
  {
    variants: {
      size: {
        small: 'w-3 h-3 radix-state-checked:translate-x-3',
        large: 'h-5 w-5 radix-state-checked:translate-x-5',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  }
);

export const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(({ size, className, ...props }, ref) => (
  <SwitchPrimitive.Root className={cn(switchCVA({ size }), className)} {...props} ref={ref}>
    <SwitchPrimitive.Thumb className={cn(toggleCVA({ size }))} />
  </SwitchPrimitive.Root>
));
