import { FC, ReactNode } from 'react';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { Spinner } from 'src/components/Spinner';

interface DialogSpinnerProps {
  message: ReactNode;
}

export const DialogSpinner: FC<DialogSpinnerProps> = ({ message }) => {
  return (
    <Dialog.Root open={true}>
      <div className="absolute bottom-0 left-0 right-0 top-0 z-10 bg-white opacity-80"></div>
      <div className="absolute bottom-0 left-0 right-0 top-0 z-20 flex flex-col items-center justify-center">
        <Spinner size="medium" />
        <p className="mt-2 text-sm text-white">{message}</p>
      </div>
    </Dialog.Root>
  );
};
