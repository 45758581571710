import { useLDFlags } from 'Hooks/useLaunchDakly';
import { DownRed, UpGreen } from 'Static/images';
import { checkConditions, getColorOverDataChange } from 'Utils';
import { cn } from 'Utils/TailwindUtils';
import { getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'Utils/enums/ChartMetadata';
import { SURVEY_TYPES } from 'Utils/enums/SourceModelTypes';
import React, { createElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MetricsType } from 'src/api/apiTypes/Summary';
import { ProgressBar } from 'src/components/ProgressBar';
import { useAppSelector } from 'src/core/store';
import { IChartComparator } from 'src/types/Filter';
import { useDrillDownContext } from 'src/ui/containers/QuickInsight/DrillDown';

interface RelevantDriverItemProps {
  metric: MetricsType;
  name: string;
  highestValue: number;
  onNameClick?: () => void;
  chartComparator?: IChartComparator;
}

export const RelevantDriverItem = ({ highestValue, chartComparator, name, metric, onNameClick }: RelevantDriverItemProps) => {
  const metricValueAndChange = getMetricValueAndPercentChange(metric, metric.metric_type, chartComparator);
  const width = (metric.value / highestValue) * 10.65;
  const metricMetadata = METRICS_VALUE_META[metric.metric_type];
  const [searchParams] = useSearchParams();
  const getCurrentValue = () => {
    let { prefixText, value: valueData } = checkConditions.getChartValueDisplayProperty(metric.value, metricMetadata);
    return prefixText + valueData;
  };
  const { selectedClientSourceData } = useDrillDownContext();
  const { surveySourceChangeColor } = useLDFlags();
  const isSurveyType = SURVEY_TYPES.includes(selectedClientSourceData?.es_alias?.type);
  const percentChangeMetadata = PERCENT_CHANGE_COMPARATOR_META[chartComparator][metric?.metric_type];
  const getPercentChangedText = () => {
    const { value, postfixText } = checkConditions.getChartValueDisplayProperty(metricValueAndChange?.change, percentChangeMetadata, true);
    return value + postfixText;
  };
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const selectedAspect = searchParams.get('aspect');
  const color = getColorOverDataChange(metric?.metric_type, metricValueAndChange?.change, isSurveyType && surveySourceChangeColor) as string;
  return (
    <div
      className="group my-3 ml-4 flex w-[85%] gap-2 text-slate-600"
      onClick={onNameClick}
      role={onNameClick ? 'button' : undefined}
      data-testid="drill-down-data"
    >
      <p
        className={cn('w-2/5 break-words capitalize group-hover:underline', {
          'font-bold text-indigo-600': name === selectedAspect || name === selectedDrillDownTopic?.topic,
        })}
      >
        {name}
      </p>
      <div className="flex w-3/5 items-center gap-2">
        <ProgressBar className="h-1.5 basis-auto bg-indigo-600" style={{ width: `${width}rem` }} />
        <p className="font-bold">
          {getCurrentValue()} {metricMetadata?.IS_PERCENT_SYMBOL_NEED ? <span className="text-xs font-normal">%</span> : null}
        </p>
        <div className="flex gap-1.5" style={{ color: color }}>
          <span className="flex items-center">
            {createElement(metricValueAndChange?.change < 0 ? DownRed : UpGreen, { stroke: color, fill: color })}
          </span>
          {getPercentChangedText()}
        </div>
      </div>
    </div>
  );
};
