import { RelevantDriver } from 'Pages/Discover/Analytics/AnalyticsPane/TopicContent/RelevantDriver';
import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import { useQuickInsight } from '../QuickInsightContainer';
import { Dimension, ISelectedClientSourceData } from 'src/types/User';
import { ISetState } from 'src/types/Globals';
import { SetDrillDownStateProps } from 'src/types/DrillDown';
import { DIY_TAG_DIMENSION_OBJECT, SENTISUM_TAG_DIMENSION_OBJECT } from 'Utils/enums/chartDimensionsType';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { IChartComparator } from 'src/types/Filter';
import { FormatFiltersV4State } from 'src/api/Tranformers';
import { useQuery } from '@tanstack/react-query';
import { fetchConversations } from 'src/api/conversation';
import { getThemeByName } from 'src/api/Theme';
import { Theme } from 'src/types/theme';

export interface DrillDownContextProps {
  dimension: Dimension;
  selectedClientSourceData: ISelectedClientSourceData;
  setDrillDownContext: ISetState<SetDrillDownStateProps>;
  chartMetric?: SOURCE_TYPES;
  chartComparator?: IChartComparator;
  selectedTopic?: string;
  chartDimensionObject?: Dimension;
  filterState?: FormatFiltersV4State;
  dimensions?: Dimension[];
  selectedTheme?: string;
}
export const DrillDownContext = createContext<DrillDownContextProps>(null);
export const DrillDown: FC = () => {
  const { selectedMetric, selectedDimension, state, selectedClientSourceData, chartComparator, topicPayload, dimension } = useQuickInsight();
  const [drillDownContext, setDrillDownContext] = useState<Omit<DrillDownContextProps, 'setDrillDownContext'>>({
    dimension: SENTISUM_TAG_DIMENSION_OBJECT,
    selectedClientSourceData: selectedClientSourceData,
  });

  const getThemeQuery = useQuery<{ data: Theme[] }>({
    queryKey: ['getThemesByName', selectedDimension],
    queryFn: () => getThemeByName(selectedDimension),
    enabled: dimension.name === 'themes',
  });

  useEffect(() => {
    if (getThemeQuery?.data)
      setDrillDownContext((prevValues) => ({
        ...prevValues,
        dimension: Object.keys(getThemeQuery?.data?.data[0].dimension_mapping).includes('diy_topic')
          ? DIY_TAG_DIMENSION_OBJECT
          : SENTISUM_TAG_DIMENSION_OBJECT,
      }));
  }, [getThemeQuery?.data]);

  const conversationDataQuery = useQuery({
    queryKey: ['fetchQuickInsightConversations', state, selectedDimension],
    queryFn: () => fetchConversations({ ...topicPayload, state: state }),
  });
  return (
    <div className="my-4 ml-8 w-full pr-10">
      <DrillDownContext.Provider
        value={{
          ...drillDownContext,
          setDrillDownContext,
          selectedClientSourceData,
          chartMetric: selectedMetric?.name,
          chartComparator,
          selectedTopic: selectedDimension,
          chartDimensionObject: dimension,
          filterState: state,
          dimensions: selectedClientSourceData?.es_alias?.dimensions,
          selectedTheme: dimension.name === 'themes' ? selectedDimension : undefined,
        }}
      >
        <RelevantDriver totalConvCount={conversationDataQuery?.data?.convdata.total} className="w-full bg-gray-50 shadow-none" />
      </DrillDownContext.Provider>
    </div>
  );
};

export const useDrillDownContext = () => {
  const context = useContext(DrillDownContext);
  if (context === null) throw new Error('useDrillDownContext must be used within a DrillDownContext');
  return context;
};
