import { useStompClient } from 'react-stomp-hooks';
import { formatFiltersV4, FormatFiltersV4Params, FormatFiltersV4State } from '../Tranformers';
import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { getAccessToken } from './clientAdapters';
import { IMessage } from '@stomp/stompjs';

export const useAuthStompClient = () => {
  const client = useStompClient();
  const authPublish = async (topic: string, body: Record<string, any>) => {
    if (!client) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (!client) throw new Error('Stomp client is not initialized');
    }
    const token = await getAccessToken();
    client.publish({
      destination: topic,
      body: JSON.stringify({
        headers: { access_token: token },
        ...body,
      }),
    });
  };
  return { authPublish, client };
};

export const useFilterStompClient = () => {
  const authClient = useAuthStompClient();
  const filterPublish = (topic: string, filterState: FormatFiltersV4State, body: Record<string, any>, filterParms?: FormatFiltersV4Params) =>
    authClient.authPublish(topic, { filtersApplied: formatFiltersV4(filterParms ?? {}, filterState), ...body });
  return { filterPublish, ...authClient };
};

export const useJSONStompSubscribe = <T,>(topic: string, callback: (data: T) => void, deps: any[] = []) => {
  const client = useStompClient();
  if (!client) throw new Error('Stomp client is not initialized');
  useEffect(() => {
    const subscription = client.subscribe(topic, (message) => {
      const result = JSON.parse(message.body) as T;
      captureSentryException<T>(result, topic, message);
      callback(result);
    });
    return () => subscription.unsubscribe();
  }, [topic, ...deps]);
};

export const captureSentryException = <T,>(result: T, topic: string, message: IMessage) => {
  if (!result || result?.['status'] !== 'success')
    Sentry.withScope((scope) => {
      scope.setTransactionName(topic);
      scope.setExtras({ message });
      Sentry.captureException(new Error('Stomp subscribe error'));
    });
};
