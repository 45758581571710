import type { FC } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi2';
import { IconButton } from 'src/components/Button';
import { Spinner } from 'src/components/Spinner';
import { useAppSelector } from 'src/core/store';

interface ConversationNavigatorProps {
  navigateConversation: (index: number) => void;
  activeConversationIndex: number;
  isPopup?: boolean;
}

export const ConversationNavigator: FC<ConversationNavigatorProps> = ({ navigateConversation, activeConversationIndex, isPopup }) => {
  const { convdata, totalConversationCount } = useAppSelector((state) => state.timeseries);
  return (
    <div className="flex items-center text-xl font-black text-black">
      <IconButton
        icon={HiOutlineChevronLeft}
        onClick={() => navigateConversation(-1)}
        disabled={!activeConversationIndex}
        className="grid place-items-center disabled:text-grey-300"
      />
      {isPopup && activeConversationIndex === 19 ? (
        <IconButton icon={HiOutlineChevronRight} disabled={true} className="grid place-items-center disabled:text-grey-300" />
      ) : activeConversationIndex < convdata?.conversations?.length - 1 || totalConversationCount - 1 == activeConversationIndex ? (
        <IconButton
          icon={HiOutlineChevronRight}
          onClick={() => navigateConversation(1)}
          disabled={activeConversationIndex === totalConversationCount - 1}
          className="grid place-items-center disabled:text-grey-300"
        />
      ) : (
        <Spinner className="text-grey-1130" size="16px" />
      )}
    </div>
  );
};
