import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'src/core/store';
import { CloseButton } from 'Static/images';
import * as NestedDialog from 'src/components/Dialog/NestedDialog';
import { FetchConversationsProps } from 'src/api/conversation';
import { resetFilterBookmarksReduxState } from 'Slices/filterbookmarks';
import { resetFilterReduxState, setPreviousSource, updateDrillDownTopic } from 'Slices/filter';
import { useNavigate } from 'react-router';
import { StompSessionProvider } from 'react-stomp-hooks';
import { Dimension, ISelectedClientSourceData, Metric } from 'src/types/User';
import { useReportFilterState } from './useFilterState';
import { InsightOverviewData } from './InsightOverviewData';
import { IChartComparator } from 'src/types/Filter';
import { FormatFiltersV4State } from 'src/api/Tranformers';
import { ISelectedDrillDownTopic } from 'Slices/types/filter';
import { IFilterGroup } from 'src/types/Bookmark';
import { useSearchParams } from 'react-router-dom';

interface IQuickInsightContext {
  selectedDimension: string;
  state: FormatFiltersV4State;
  topicPayload: FetchConversationsProps;
  selectedMetric: Metric;
  chartComparator: IChartComparator;
  selectedClientSourceData: ISelectedClientSourceData;
  dimension: Dimension;
}

const QuickInsightContext = createContext<IQuickInsightContext>(null);

const QuickInsightContainer = ({ data, selectedDimension }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedMetric, setSelectedMetric] = useState<Metric>();
  const { state, topicPayload, source, metrics, comparisons, selectedClientSourceData, dimension } = useReportFilterState(data, selectedDimension);
  useEffect(() => setSelectedMetric(metrics[0]), [metrics[0]]);
  const navigateToDiscover = () => {
    dispatch(resetFilterBookmarksReduxState());
    dispatch(setPreviousSource(source));
    dispatch(resetFilterReduxState(state));
    navigate(`/discover?topic=${selectedDimension}${searchParams.get('aspect') ? `&aspect=${searchParams.get('aspect')}` : ''}`);
  };
  return (
    <QuickInsightContext.Provider
      value={{
        selectedDimension,
        state,
        topicPayload,
        selectedMetric,
        chartComparator: comparisons[0] as IChartComparator,
        selectedClientSourceData,
        dimension,
      }}
    >
      <StompSessionProvider url={process.env.REACT_APP_SUMMARY_WEBSOCKET_URL}>
        <InsightOverviewData
          reportMetric={metrics[0]}
          source={source}
          selectedClientSourceData={selectedClientSourceData}
          navigateToDiscover={navigateToDiscover}
          setSelectedMetric={setSelectedMetric}
        />
        <NestedDialog.Close className="fixed -right-[1.5rem] -top-5">
          <CloseButton data-testid="close-quick-insight" />
        </NestedDialog.Close>
      </StompSessionProvider>
    </QuickInsightContext.Provider>
  );
};

export const useQuickInsight = () => {
  const context = useContext(QuickInsightContext);
  if (!context) throw new Error('useQuickInsight must be used within QuickInsightData');
  return context;
};

export const getDrillDownFilter = (selectedDrillDownTopic: ISelectedDrillDownTopic): IFilterGroup => {
  const dimensionName = selectedDrillDownTopic?.dimension.name;
  const fieldMappings: Record<string, { type: string; fieldName: string }> = {
    topic: { type: 'topic', fieldName: 'annotations.topic' },
    diy_topic: { type: 'diyTopic', fieldName: 'segments.text' },
    themes: { type: 'themes', fieldName: 'NO_PATH' },
  };

  const { type, fieldName } = fieldMappings[dimensionName] || {
    type: 'metadata',
    fieldName: selectedDrillDownTopic?.dimension.path.replace('metadata.', ''),
  };

  return {
    conditionalFilterRelation: 'or',
    conditionalFilters: [
      {
        type,
        fieldName,
        valuesRelation: 'or',
        values: [selectedDrillDownTopic?.topic],
      },
    ],
  };
};

export default QuickInsightContainer;
