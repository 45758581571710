import BarSkeleton from 'src/components/SkeletonPlaceHolder/BarSkeleton';

const AnalaticsCardLoader = () => {
  return (
    <div className="flex w-full items-center justify-between py-3 pl-9 pr-3">
      <div className="flex w-[30%] flex-col gap-1.5">
        <BarSkeleton height={22} width="30%" />
        <BarSkeleton height={15} />
      </div>
      <div className="w-[30%]">
        <BarSkeleton height={30} />
      </div>
    </div>
  );
};

export default AnalaticsCardLoader;
