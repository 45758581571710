import { useAppSelector } from 'src/core/store';
import { FormatFiltersV4State, transformFilters, transformViewToFilters } from 'src/api/Tranformers';
import { SENTISUM_TAG_TYPES } from 'Utils/constants/common';
import { FetchConversationsProps } from 'src/api/conversation';
import { getReportConfiguration } from 'src/api/dashboard/Report';
import { cloneDeep } from 'lodash';
import { getDrillDownFilter } from './QuickInsightContainer';
import { TRACK_EVENT, trackDashboardEvent } from 'src/core/trackEvent';
import { DigInSummary } from 'src/types/DigIn';
import { captureSentryException, useFilterStompClient } from 'src/api/utils/stompClintHooks';
import { ISetState } from 'src/types/Globals';
import { QuickInsightDigInState } from './QuickSummaryDigInContext';
import { ISelectedDrillDownTopic } from 'Slices/types/filter';
import { Dimension, ISelectedClientSourceData } from 'src/types/User';

export const useReportFilterState = (data: Awaited<ReturnType<typeof getReportConfiguration>>, selectedDimension: string) => {
  const { filters: dashboardFilters, chartIntervalBreakdown } = useAppSelector((state) => state.dashboard);
  const reportType = data.configuration.report_type;
  if (reportType === 'DOC' || reportType === 'MC' || !('selectedClientSourceData' in data)) return null;
  const { selectedClientSourceData, configuration } = data;
  const { dimension, metrics, source, conditions, comparisons } = configuration;
  const state: FormatFiltersV4State = {
    selectedSourceClientData: selectedClientSourceData,
    chartDimensionObject: dimension,
    chartDimension: dimension.name,
    chartMetric: metrics[0].name,
    chartMetricObject: metrics[0],
    filters: {
      ...dashboardFilters,
      source,
      metadata: conditions.dataSourceFilters ?? [],
    },
    bookmarkFilters: transformViewToFilters({ dataSourceFilters: conditions.dataSourceFilters ?? [], search: '' }),
    chartIntervalBreakdown: chartIntervalBreakdown,
  };

  let topicPayload: FetchConversationsProps = {};
  if (dimension.name === SENTISUM_TAG_TYPES.DIY_TAG) topicPayload.diy_topic = selectedDimension;
  else if (dimension.name === SENTISUM_TAG_TYPES.SENTISUM_TAG) topicPayload.topic = selectedDimension;
  else topicPayload.dimension_values = selectedDimension;

  return { state, topicPayload, source, metrics, comparisons, selectedClientSourceData, dimension };
};

export const useFetchQuickSummary = (setDigInState: ISetState<QuickInsightDigInState>) => {
  const { filterPublish, client } = useFilterStompClient();
  const fetchQuickSummary = async (
    state: FormatFiltersV4State,
    selectedDrillDownTopic: ISelectedDrillDownTopic,
    selectedClientSourceData: ISelectedClientSourceData,
    dimension: Dimension,
    topicPayload: FetchConversationsProps
  ) => {
    let updatedFilterState: FormatFiltersV4State = cloneDeep(state);

    if (selectedDrillDownTopic) {
      selectedClientSourceData?.es_alias?.integrations.forEach((integration) => {
        const key = integration.name;
        updatedFilterState.bookmarkFilters.datasourceFilters[key] = [
          ...(updatedFilterState.bookmarkFilters.datasourceFilters[key] || []),
          getDrillDownFilter(selectedDrillDownTopic),
        ];
      });
      updatedFilterState.filters.metadata = transformFilters(updatedFilterState.bookmarkFilters);
    }
    await filterPublish(
      '/app/qna',
      updatedFilterState,
      { summaryType: dimension.name === 'themes' ? 'ThemeQuickSummary' : 'TopicQuickSummary' },
      topicPayload
    );

    setDigInState({ summaryStatus: 'pending' });

    client?.subscribe('/user/topic/qna', (message) => {
      const result = JSON.parse(message.body) as DigInSummary;
      if (result?.status === 'success') {
        setDigInState({ summary: result, summaryStatus: 'fulfilled' });
        trackDashboardEvent(TRACK_EVENT.QUICK_SUMMARY_RESULT, { quickSummaryResult: result?.answer, runId: result?.id });
      } else {
        setDigInState({ summaryStatus: 'rejected', summaryError: result?.answer });
        trackDashboardEvent(TRACK_EVENT.QUICK_SUMMARY_FAILED, { error: result?.answer, runId: result?.id });
        captureSentryException<DigInSummary>(result, '/user/topic/qna', message);
        client?.unsubscribe('/user/topic/qna');
      }
    });
  };

  return { fetchQuickSummary };
};
