import { MOMENT_CHART_TOOLTIP_DATE_FORMAT, getChartDateLabel, getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { TEXT_TYPES, LANG_TYPES } from 'Constants/languages';
import { textOperations, checkConditions, getColorOverDataChange, getDriverData } from 'Utils';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'Enums/ChartMetadata';
import { INTERVAL_BREAKDOWN_TYPES } from 'src/core/utils/enums/chartIntervalBreakdownTypes';
import { GraphTooltip } from 'src/components/Tooltip';
import { IChartComparator } from 'src/types/Filter';
import { Topic } from 'src/api/apiTypes/Summary';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { useLDFlags } from 'Hooks/useLaunchDakly';

interface CustomDriverGraphTooltipProps {
  selectedMetricType: string;
  chartComparator: IChartComparator;
  driver: Topic;
  isBarChart?: boolean;
  isSurveyType?: boolean;
}

export const CustomDriverGraphTooltip: React.FC<PropsWithChildren<CustomDriverGraphTooltipProps>> = (props) => {
  const { selectedMetricType, chartComparator, driver, isBarChart, isSurveyType, children } = props;
  const { surveySourceChangeColor } = useLDFlags();
  const data = getMetricValueAndPercentChange(driver.current_interval.metrics, selectedMetricType, chartComparator);
  const previousData = getMetricValueAndPercentChange(driver.previous_interval.metrics, selectedMetricType, chartComparator);
  const chartColor = getColorOverDataChange(selectedMetricType, data?.change, isSurveyType && surveySourceChangeColor) as string;
  const currentRange = driver.current_interval.range;
  const previousRange = driver.previous_interval.range;

  const getValueText = useCallback(
    (valueData) => {
      let { prefixText, value, postfixText } = checkConditions.getChartValueDisplayProperty(valueData, METRICS_VALUE_META[selectedMetricType]);
      const text = prefixText + value + postfixText;
      return text;
    },
    [selectedMetricType]
  );
  const getPercentChangedText = (valueData, metric) => {
    let { value, postfixText } = checkConditions.getChartValueDisplayProperty(valueData, PERCENT_CHANGE_COMPARATOR_META[chartComparator][metric]);
    return Math.abs(value) + postfixText;
  };

  const currentValue = useMemo(() => getValueText(data.value), [data, getValueText]);
  const previousValue = useMemo(() => getValueText(previousData.value), [previousData, getValueText]);
  const currentLabel = useCallback(
    () =>
      `${getChartDateLabel(currentRange, INTERVAL_BREAKDOWN_TYPES.DAY, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}` +
      (currentRange?.end && ` - ${getChartDateLabel({ start: currentRange?.end }, INTERVAL_BREAKDOWN_TYPES.DAY, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}`),
    [data, getChartDateLabel]
  );

  const previousLabel = useCallback(
    () =>
      `${getChartDateLabel(previousRange, INTERVAL_BREAKDOWN_TYPES.DAY, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}` +
      (previousRange?.end &&
        ` - ${getChartDateLabel({ start: previousRange?.end }, INTERVAL_BREAKDOWN_TYPES.DAY, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}`),
    [previousData, getChartDateLabel]
  );

  const title = useMemo(() => textOperations.getTitleName(selectedMetricType, LANG_TYPES.EN, TEXT_TYPES.ALIAS), [selectedMetricType]);

  const { noOfResponses } = useMemo(
    () => getDriverData(driver, chartComparator, selectedMetricType as SOURCE_TYPES, true),
    [selectedMetricType, chartComparator, driver]
  );
  return (
    <GraphTooltip
      graphCurrentColor={chartColor}
      title={title}
      currentValue={currentValue}
      getCurrentDateLabel={currentLabel}
      previousValue={previousValue}
      getPreviousDateLabel={previousLabel}
      change={data.change}
      changeText={getPercentChangedText(data.change, selectedMetricType)}
      driver={isBarChart ? driver : undefined}
      selectedMetricType={selectedMetricType}
      noOfResponses={noOfResponses}
    >
      {children}
    </GraphTooltip>
  );
};
