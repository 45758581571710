import { Fragment } from 'react';
import { SummaryCardBase } from 'src/components/Card';
import AnalaticsCardLoader from './AnalaticsCardLoader';

const AnalyticsLoader = () => {
  return (
    <SummaryCardBase className="h-full overflow-hidden p-0">
      <div className="h-11 border-b"></div>
      <div className="relative flex-grow">
        {[...Array(8)].map((_, key) => (
          <Fragment key={key}>
            <AnalaticsCardLoader />
            <div className="border-b"></div>
          </Fragment>
        ))}
      </div>
    </SummaryCardBase>
  );
};

export default AnalyticsLoader;
