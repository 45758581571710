import React from 'react';
import { useFilterStompClient } from 'src/api/utils/stompClintHooks';
import { Button } from 'src/components/Button';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';
import { useQuickInsight } from '../QuickInsightContainer';

export const FailedToGenerateQuickSummary = () => {
  const [_, setDigInState] = useQuickSummaryDiginContext();
  const { filterPublish } = useFilterStompClient();
  const { state, selectedDimension } = useQuickInsight();
  const { dimension } = useQuickInsight();
  const handleTryAgain = async () => {
    await filterPublish(
      '/app/qna',
      state,
      { summaryType: dimension.name === 'themes' ? 'ThemeQuickSummary' : 'TopicQuickSummary' },
      { topic: selectedDimension }
    );
    setDigInState({ summaryStatus: 'pending' });
  };
  return (
    <div className="-ml-8 -mt-6 flex w-full flex-col items-center justify-center gap-3 text-sm">
      <img src={'/images/error/sadDog1.png'} alt="Failed to generate summary" loading="lazy" width={140} height={140} />
      <div className="flex w-80 justify-center text-balance text-center text-sm font-semibold leading-tight text-slate-800">
        We apologise, but we’re unable to generate summary at the moment.
      </div>
      <div className="flex items-center justify-center gap-4 pt-4 text-sm">
        <Button variant="primary" label="Please try again" className="rounded-full" onClick={handleTryAgain} />
      </div>
    </div>
  );
};
