import { SummaryCardBase } from 'src/components/Card';
import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';
import InboxCardLoader from './InboxCardLoader';
import { cn } from 'Utils/TailwindUtils';
import { FC } from 'react';

const InboxLoader: FC<{ className?: string }> = ({ className }) => {
  return (
    <SummaryCardBase className={cn('h-full p-0', className)}>
      <div className="h-42 border-b px-4 py-2">
        <div className="w-full">
          <BarSkeleton width="20%" />
        </div>
      </div>
      <div className="h-42 border-b px-4 py-2">
        <div className="w-full">
          <BarSkeleton width="20%" />
        </div>
      </div>
      {[...Array(8)].map((_, key) => (
        <InboxCardLoader key={key} />
      ))}
    </SummaryCardBase>
  );
};

export default InboxLoader;
