import moment from 'moment';
import { useAppSelector } from 'src/core/store';
import { InboxCardLoader } from 'src/ui/pages/Discover/SkeletonPlaceholder';
import InboxCard from './InboxCard';
import { ExtendedConversationData } from './types';
import { FC } from 'react';
import { cn } from 'Utils/TailwindUtils';

interface InboxPaneProps {
  items: ExtendedConversationData[];
  activeId: string;
  onStar: (item: ExtendedConversationData, isStar: boolean) => void;
  onRead: (event: React.MouseEvent, item: ExtendedConversationData, index: number) => void;
  onScrollEnd?: (page: number) => void;
  className?: string;
  extra?: React.ReactNode;
}

export const InboxPane: FC<InboxPaneProps> = ({ items, activeId, onStar, onRead, onScrollEnd, className, extra }) => {
  const { convdataPage, convdataFetchStatus } = useAppSelector((state) => state.timeseries);
  const getDateToAdd = (date, prevDate) => {
    date = moment.utc(date).local().startOf('day');
    prevDate = prevDate && moment.utc(prevDate).local().startOf('day');
    let todayDate = moment().local().startOf('day');
    if (!prevDate || prevDate.isAfter(date, 'day')) {
      let diffFromToday = todayDate.diff(date, 'days');
      let dateFormat = diffFromToday === 0 ? '[TODAY]' : diffFromToday === 1 ? '[YESTERDAY]' : "D MMM 'YY";
      return date.format(dateFormat);
    }
  };
  const handleScroll = (e) => {
    if (convdataFetchStatus !== 'pending' && !!convdataPage && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 750) {
      onScrollEnd?.(convdataPage);
    }
  };
  return (
    <div className={cn('relative grow', className)}>
      {items?.length > 0 ? (
        <div onScroll={handleScroll} className="absolute inset-0 overflow-y-auto" tabIndex={-1}>
          <>
            {items.map((item, i) => {
              let dateToAdd = getDateToAdd(item.date, i !== 0 && items[i - 1].date);
              return (
                <div key={item.id} className="grow border-b">
                  {i !== 0 && dateToAdd && <div className="border-b py-2 pl-7 text-xs text-grey-500">{dateToAdd}</div>}
                  <InboxCard
                    {...item}
                    index={i}
                    active={activeId === item.id}
                    onStar={(isStar) => onStar(item, isStar)}
                    onRead={(event) => onRead(event, item, i)}
                  />
                </div>
              );
            })}
            {extra}
            {convdataFetchStatus === 'pending' && <InboxCardLoader />}
          </>
        </div>
      ) : (
        <div className="flex h-full grow items-center justify-center text-xs text-grey-500">No items found</div>
      )}
    </div>
  );
};
