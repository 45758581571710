import { BsGraphUp } from 'react-icons/bs';
import { HiOutlineChartBar, HiOutlineTableCells } from 'react-icons/hi2';
import { IconType } from 'react-icons/lib';
import { ToggleGroupOption } from 'src/components/ToggleGroup/types';

export const getSummaryOptions: (hideTooltip?: boolean) => ToggleGroupOption<IconType>[] = (hideTooltip = false) => [
  {
    value: 'T',
    label: HiOutlineTableCells,
    'data-tooltip-content': 'Table',
    'data-tooltip-place': 'right',
    'data-tooltip-id': 'tooltip',
    'data-tooltip-hidden': hideTooltip,
  },
  {
    value: 'VBG',
    label: HiOutlineChartBar,
    'data-tooltip-content': 'Vertical Bar Graph',
    'data-tooltip-place': 'right',
    'data-tooltip-id': 'tooltip',
    'data-tooltip-hidden': hideTooltip,
  },
  {
    value: 'HBG',
    label: HiOutlineChartBar,
    className: '*:rotate-90 *:scale-x-[-1]',
    'data-tooltip-content': 'Horizontal Bar Graph',
    'data-tooltip-place': 'right',
    'data-tooltip-id': 'tooltip',
    'data-tooltip-hidden': hideTooltip,
  },
];

export const getOverTimeOptions: (hideTooltip?: boolean) => ToggleGroupOption<IconType>[] = (hideTooltip = false) => [
  {
    value: 'LG',
    label: BsGraphUp,
    'data-tooltip-content': 'Line Graph',
    'data-tooltip-place': 'right',
    'data-tooltip-id': 'tooltip',
    'data-tooltip-hidden': hideTooltip,
  },
  {
    value: 'VBG',
    label: HiOutlineChartBar,
    'data-tooltip-content': 'Bar Graph',
    'data-tooltip-place': 'right',
    'data-tooltip-id': 'tooltip',
    'data-tooltip-hidden': hideTooltip,
  },
];
