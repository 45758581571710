export const CONVERSATION_SOURCE_VIEW_TYPES = {
  TICKET: 'ticket',
  CSAT: 'CSAT',
};

export const SUPPORT_SOURCE_CONVERSATION_TAB_LIST = [
  CONVERSATION_SOURCE_VIEW_TYPES.TICKET,
  CONVERSATION_SOURCE_VIEW_TYPES.CSAT,
];
export const CSAT_SOURCE_CONVERSATION_TAB_LIST = [
  CONVERSATION_SOURCE_VIEW_TYPES.CSAT,
  CONVERSATION_SOURCE_VIEW_TYPES.TICKET,
];

export const CONVERSATION_SOURCE_VIEW_TYPES_PROPS = {
  [CONVERSATION_SOURCE_VIEW_TYPES.TICKET]: {},
  [CONVERSATION_SOURCE_VIEW_TYPES.CSAT]: {},
};
