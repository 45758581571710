import { cn } from 'Utils/TailwindUtils';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { useAppSelector } from 'src/core/store';

interface ChatHighlighterProps extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
}

export const ChatHighlighter: React.FC<ChatHighlighterProps> = ({ message, className }) => {
  const searchString = useAppSelector((state) => state.filter.searchString);
  const diyTopicKeywords = useAppSelector((state) => state.analytics.diyTopicKeywords);
  const searchWords = diyTopicKeywords.concat(searchString.split(','));
  return (
    <Highlighter
      highlightClassName="py-px px-1 bg-highlight-500"
      searchWords={searchWords}
      autoEscape
      textToHighlight={message}
      className={cn('whitespace-pre-wrap', className)}
    />
  );
};
