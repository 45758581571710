import { FiCopy, FiMinus } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { checkConditions, getColorOverDataChange } from 'Utils';
import { getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'Utils/enums/ChartMetadata';
import { cn } from 'Utils/TailwindUtils';
import { MouseEvent, createElement, memo } from 'react';
import { DownRed, UpGreen } from 'Static/images';
import { MetricReportConfigration } from 'src/api/apiTypes/Report';
import { useQuery } from '@tanstack/react-query';
import { getMetricCardDataFromConfig, getSelectedClientSourceData } from 'src/api/dashboard/Report';
import Skeleton from 'react-loading-skeleton';
import { selectMetricCard, updateMetricCards, updateShowSuggestedMetricInfo } from 'Slices/editMetricCard';
import { useDroppable } from '@dnd-kit/core';
import { SourceNameLabel } from '../SourceNameLabel';
import { nanoid } from '@reduxjs/toolkit';
import { metricCardBgColors } from './MetricCardGrid';
import { SURVEY_TYPES } from 'Utils/enums/SourceModelTypes';
import { useLDFlags } from 'Hooks/useLaunchDakly';

export const getNextBgColor = (metricCards: MetricReportConfigration[]) => {
  const usedColors = metricCards.map((card) => card.bgColor);
  return metricCardBgColors.filter((color) => !usedColors.includes(color))?.[0];
};

export const ConfigMetricCard = memo(
  ({
    metric,
    idx,
    isOver,
    isInternalOver,
    isDraging,
  }: {
    metric: MetricReportConfigration;
    idx?: number;
    isOver?: boolean;
    isInternalOver?: boolean;
    isDraging?: boolean;
  }) => {
    const { setNodeRef } = useDroppable({ id: metric.id });
    const { metricCards, showSuggestedMetricInfo } = useAppSelector((state) => state.editMetricCard);
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const chartComparator = metric.comparisons[0];
    const dateData = useAppSelector((state) => state.editMetricCard.dateRangeFilter);
    const { bgColor, ...metricCardData } = metric;
    const reportDataQuery = useQuery({
      queryKey: ['getMetricCardDataFromConfig', metricCardData, dateData],
      queryFn: async () => {
        const result = await getMetricCardDataFromConfig(metricCardData, dateData);
        return result.data.current_interval.metrics[0];
      },
    });
    const removeMetricCard = (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(updateMetricCards(metricCards.filter((_, index) => index !== idx)));
    };
    const duplicateMetricCard = (e: MouseEvent) => {
      e.stopPropagation();
      const newMetricCards = [...metricCards];
      newMetricCards.splice(idx + 1, 0, {
        ...metric,
        id: nanoid(),
        bgColor: getNextBgColor(metricCards),
        name: `Copy of ${metric.name}`,
      });
      dispatch(updateMetricCards(newMetricCards));
    };
    const onCardClick = () => {
      dispatch(selectMetricCard({ index: idx, user }));
      if (showSuggestedMetricInfo) dispatch(updateShowSuggestedMetricInfo(false));
    };
    if (reportDataQuery.isLoading) return <Skeleton height={117} />;
    const metricData = reportDataQuery.data;
    const metricValueAndChange = getMetricValueAndPercentChange(metricData, metricData?.metric_type, chartComparator);
    const metricMetadata = METRICS_VALUE_META[metricData?.metric_type];
    const getCurrentValue = () => {
      let { prefixText, value: valueData } = checkConditions.getChartValueDisplayProperty(metricData?.value, metricMetadata);
      return prefixText + valueData;
    };
    const percentChangeMetadata = PERCENT_CHANGE_COMPARATOR_META[chartComparator][metricData?.metric_type];
    const getPercentChangedText = () => {
      const { value, postfixText } = checkConditions.getChartValueDisplayProperty(metricValueAndChange?.change, percentChangeMetadata, true);
      return value + postfixText;
    };
    const { surveySourceChangeColor } = useLDFlags();
    const isSurveyType = SURVEY_TYPES.includes(getSelectedClientSourceData(metric?.source)?.es_alias?.type);
    const color = getColorOverDataChange(metricData?.metric_type, metricValueAndChange?.change, surveySourceChangeColor && isSurveyType) as string;
    const metricName = metric?.name !== metric?.metrics[0]?.name ? metric.name : metric?.metrics[0]?.display_name;
    const souceDisplayName = getSelectedClientSourceData(metric.source)?.es_alias.display_name;
    if (reportDataQuery.isError) return <div className="flex items-center justify-center text-gray-200">Failed to load data</div>;
    return (
      <div
        ref={setNodeRef}
        className={cn(
          'group relative min-h-28 rounded-xl',
          metric.bgColor,
          isOver ? 'border border-dashed border-indigo-600' : 'border border-white',
          isInternalOver ? 'border border-dashed border-indigo-600' : undefined,
          isDraging ? 'border border-indigo-600' : undefined
        )}
        role="button"
        onClick={onCardClick}
      >
        {!isOver && metricCards.length < 4 && (
          <span
            className="absolute !-top-2 !right-7 z-50 hidden rounded-full border bg-white p-1.5 !text-gray-500 group-hover:flex hover:!text-gray-800"
            onClick={duplicateMetricCard}
          >
            <FiCopy className="flex items-center" />
          </span>
        )}
        {!isOver && !isDraging && (
          <span
            className="absolute !-right-2 !-top-2 z-50 hidden rounded-full border bg-white p-1.5 text-gray-500 group-hover:flex hover:text-red-700"
            onClick={removeMetricCard}
          >
            <FiMinus className="flex items-center" />
          </span>
        )}
        <SourceNameLabel sourceName={souceDisplayName} />
        <div className="px-5 py-2.5 text-sm">
          <div>{metricName?.length > 32 ? `${metricName?.slice(0, 32)}...` : metricName}</div>
          <div className="mt-3 flex items-end gap-2">
            <p className="flex items-center text-3xl font-bold">
              {getCurrentValue()} {metricMetadata?.IS_PERCENT_SYMBOL_NEED ? <span className="pl-1 text-2xl font-normal">%</span> : null}
            </p>
            <div className="mb-1 flex gap-1.5" style={{ color: color }}>
              <span className="flex items-center justify-center">
                {createElement(metricValueAndChange?.change < 0 ? DownRed : UpGreen, { stroke: color, fill: color })}
              </span>
              {getPercentChangedText()}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
