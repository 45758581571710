import { forwardRef } from 'react';
import { Box, Text } from 'grommet';

const SentimentTag = forwardRef(({ name, sentiment, margin, ...rest }, ref) => (
  <Box
    alignSelf="start"
    round="xsmall"
    background={`${sentiment}-dark-opacity`}
    pad={{ horizontal: 'small', bottom: '2px' }}
    margin={margin}
    ref={ref}
    {...rest}
  >
    <Text truncate size="small" className="capitalize" color={`${sentiment}-dark`}>
      {name}
    </Text>
  </Box>
));

export default SentimentTag;
