import SummaryError from 'Components/SummaryError';
import { useMemo } from 'react';
import { OverTimeData } from 'src/api/apiTypes/Summary';
import { SummaryGraph } from 'src/components/Charts/LineCharts';
import { IChartComparator } from 'src/types/Filter';
import { HTMLDivProps, IApiCallStatus } from 'src/types/Globals';
import { textOperations } from 'Utils';
import { formChartValueOverTime, getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { LANG_TYPES, TEXT_TYPES } from 'Utils/constants/languages';
import { METRICS_VALUE_META } from 'Utils/enums/ChartMetadata';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { cn } from 'Utils/TailwindUtils';
import { ReportHeaderTab } from './ReportGraphHeader';
import { SummaryGraphCardLoader } from './SummaryGraphCardLoader';
import { useRaf } from 'react-use';

const chartProps = {
  margin: { top: 0, right: 5, left: -9, bottom: 0 },
};

const tooltipProps = {
  allowEscapeViewBox: { x: false, y: true },
  offset: -20,
};

export interface SummaryOverviewGraphProps extends HTMLDivProps {
  selectedSummaryGraphData: OverTimeData;
  selectedMetricType: SOURCE_TYPES;
  fetchDataStatus: IApiCallStatus;
  chartComparator: IChartComparator;
  animationDisabled?: boolean;
  isSurveyType?: boolean;
}

const getLines = (diasableAnimation: boolean) => {
  return {
    previousDataIsAnimationActive: !diasableAnimation,
    dataIsAnimationActive: !diasableAnimation,
  };
};

export const OverviewLineGraph: React.FC<SummaryOverviewGraphProps> = (props) => {
  const elapsed = useRaf(5000, 1000);
  const { selectedSummaryGraphData, selectedMetricType, fetchDataStatus, isSurveyType, chartComparator, className, animationDisabled, ...rest } =
    props;
  const overAllMetricObject = useMemo(
    () => getMetricValueAndPercentChange(selectedSummaryGraphData?.current_interval?.metrics, selectedMetricType, chartComparator),
    [selectedSummaryGraphData, selectedMetricType, chartComparator]
  );

  const valueOverTime = formChartValueOverTime(selectedSummaryGraphData, selectedMetricType, chartComparator);
  const chartYAxisMetaData = () => ({
    show: true,
    label: textOperations.getTitleName(selectedMetricType, LANG_TYPES.EN, TEXT_TYPES.ALIAS),
    isDecimal: METRICS_VALUE_META[selectedMetricType]?.IS_DECIMAL,
  });
  if (fetchDataStatus === 'fulfilled' && selectedSummaryGraphData) {
    return (
      <div className={cn('flex h-full w-full flex-col gap-8', className)} {...rest}>
        <ReportHeaderTab
          chartMetric={selectedMetricType}
          chartComparator={chartComparator}
          overAllMetricObject={overAllMetricObject}
          isSurveyType={isSurveyType}
        />
        <SummaryGraph
          height="75%"
          selectedMetricType={selectedMetricType}
          valueOverTime={valueOverTime}
          changeValue={overAllMetricObject?.change}
          YAxisMeta={chartYAxisMetaData()}
          chartComparator={chartComparator}
          chartProps={chartProps}
          tooltipProps={tooltipProps}
          lines={getLines(elapsed === 1 || animationDisabled)}
          isSurveyType={isSurveyType}
        />
      </div>
    );
  } else if (fetchDataStatus === 'rejected') {
    return <SummaryError />;
  } else {
    return <SummaryGraphCardLoader />;
  }
};
