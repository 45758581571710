import React, { FC } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { HTMLButtonProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';

interface CarouselArrowProps extends HTMLButtonProps {
  side: 'left' | 'right';
}

export const CarouselArrow: FC<CarouselArrowProps> = ({ side, className, ...rest }) => {
  return (
    <span
      className={cn('absolute inset-0 flex w-16 items-center justify-center', className, {
        'left-auto fade-transparent': side === 'right',
        'fade-transparent-left': side === 'left',
      })}
      data-side={side}
    >
      <button className="flex items-center justify-center rounded-full px-2.5 py-2 hover:bg-gray-1000" {...rest}>
        {side === 'left' ? <AiOutlineLeft /> : <AiOutlineRight />}
      </button>
    </span>
  );
};
