import { random } from 'lodash';
import React, { useState } from 'react';
import { FiClock } from 'react-icons/fi';
import { cn } from 'Utils/TailwindUtils';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';


interface SummaryLoaderProps {
  timer: number;
}
export const SummaryLoader: React.FC<SummaryLoaderProps> = ({ timer }) => {
  const [image] = useState(`/images/loading-animations/${random(1, 2)}.gif`);
  return (
    <div className="-ml-14 flex w-full flex-col items-center justify-center gap-4 py-8 text-gray-510">
      <div
        className="w-fit rounded-full from-gray-1100 to-indigo-600 p-0.5"
        style={{ background: `conic-gradient(from 0deg, var(--tw-gradient-to) ${timer}%, var(--tw-gradient-from) ${timer}% 100%)` }}
      >
        <div className="flex rounded-full bg-white p-1.5">
          <img src={image} loading="lazy" width={170} height={170} />
        </div>
      </div>
      <span className="text-sm text-gray-600">Generating Summary...</span>
      <div className={cn('flex w-fit items-center gap-2 bg-green-50 p-2 text-xs text-green-700', { 'bg-warning-50 text-warning-700': timer > 50 })}>
        <FiClock className="shrink-0 text-base" />
        <span>{timer > 50 ? "It's taking a bit longer than expected" : 'This can take around 20-30 seconds'}</span>
      </div>
    </div>
  );
};
