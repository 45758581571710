import React, { useState } from 'react';
import { RelevantDriverItem } from './RelevantDriverData/RelevantDriverItem';
import { RelevantDriverContainer } from './RelevantDriverData/RelevantDriverContainer';
import { useAppSelector } from 'src/core/store';
import { Topic } from 'src/api/apiTypes/Summary';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchAspect } from 'src/api/Analytics';
import { DialogSpinner } from 'src/components/Dialog/DialogSpinner';
import { useDrillDownContext } from 'src/ui/containers/QuickInsight/DrillDown';

export const CommonPhrasesTab = ({ chartDimensionObject, chartComparator, selectedTopic }) => {
  const [_, setSearchParams] = useSearchParams();
  const [showAll, setShowAll] = useState(false);
  const { searchString, selectedAspect } = useAppSelector((state) => state.filter);
  const { filterState } = useDrillDownContext();
  const aspectQuery = useQuery({
    queryKey: ['aspect', searchString, selectedTopic],
    queryFn: () => fetchAspect({ topic: selectedTopic, dimension: chartDimensionObject, search: searchString, filterState }),
    staleTime: 0,
  });

  const aspectData = aspectQuery?.data?.aspects;
  const toggleAspect = (aspect: Topic) => {
    trackFilterEvent(TRACK_EVENT.ASPECT_SELECT, { Value: aspect?.name });
    setSearchParams((params) => {
      if (selectedAspect === aspect.name) params.delete('aspect');
      else params.set('aspect', aspect.name);
      return params;
    });
  };
  return (
    <RelevantDriverContainer driversLength={aspectData?.length} showAll={showAll} setShowAll={setShowAll}>
      {aspectQuery.isLoading ? (
        <DialogSpinner message="Loading..." />
      ) : (
        <>
          {(showAll ? aspectData : aspectData?.slice(0, 5))?.map((data, id) => (
            <RelevantDriverItem
              metric={data.current_interval.metrics[0]}
              onNameClick={() => toggleAspect(data)}
              name={data.name}
              highestValue={aspectData[0].current_interval.metrics[0].value}
              key={id}
              chartComparator={chartComparator}
            />
          ))}
        </>
      )}
    </RelevantDriverContainer>
  );
};
