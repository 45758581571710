import React, { FC } from 'react';
import { Topic } from 'src/api/apiTypes/Summary';
import { IChartComparator } from 'src/types/Filter';
import { ReportType } from 'src/types/Report';
import { Metric } from 'src/types/User';
import { CHART_COMPARATOR_TYPES_ENUM } from 'Utils/enums/ChartComparatorTypes';
import { LANG_TYPES, TEXT_TYPES } from 'Utils/constants/languages';
import { TopIncreaseDecreaseVBG } from './TopIncreaseDecreaseVBG';
import { HighestLowestVBG } from './HighestLowestVBG';

export interface VerticalBarGraphContainerProps {
  drivers: Topic[];
  chartMetricObject: Metric;
  showDateRange?: boolean;
  chartComparator: IChartComparator;
  tableVisualization?: ReportType;
  onClick?: (path: string) => void;
  highestAndlowestValue?: { highestValue: number; lowestValue: number };
  hideLabels?: boolean;
  isSurveyType?: boolean;
}

export const VerticalBarGraphContainer: FC<VerticalBarGraphContainerProps> = (props) => {
  const { tableVisualization, chartMetricObject, chartComparator } = props;

  if (tableVisualization === 'H' || tableVisualization === 'L') return <HighestLowestVBG label={chartMetricObject.display_name} {...props} />;
  else if (tableVisualization === 'TI' || tableVisualization === 'TD')
    return <TopIncreaseDecreaseVBG label={CHART_COMPARATOR_TYPES_ENUM[chartComparator][LANG_TYPES.EN][TEXT_TYPES.LONG]} {...props} />;
  else return null;
};
