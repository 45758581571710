import React, { FC, useCallback, useEffect, useRef } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { CarouselArrow } from 'src/components/Carousel/TabsCarousel/CarouselArrow';
import * as Tabs from '@radix-ui/react-tabs';
import { useScroll } from 'react-use';
import { SENTISUM_TAG_TYPES } from 'Utils/constants/common';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { useDrillDownContext } from 'src/ui/containers/QuickInsight/DrillDown';
import { updateDrillDownTopic } from 'Slices/filter';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { useSearchParams } from 'react-router-dom';

interface RelevantDriverCarouselProps {
  setTheme: (theme: string) => void;
}

export const RelevantDriverCarousel: FC<RelevantDriverCarouselProps> = ({ setTheme }) => {
  const flags = useLDFlags();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dimension, setDrillDownContext, dimensions, chartDimensionObject } = useDrillDownContext();
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const chartDimension = chartDimensionObject?.name;
  const scrollRef = useRef<HTMLDivElement>(null);
  const scroll = useCallback(
    (direction: 'left' | 'right') => scrollRef.current?.scrollBy({ left: direction === 'left' ? -100 : 100, behavior: 'smooth' }),
    []
  );
  const changeDimension = (value: string) => {
    if (value === 'common_phrases') setDrillDownContext({ dimension: { name: 'common_phrases', display_name: 'Common Phrases', path: '' } });
    else setDrillDownContext((prevValues) => ({ ...prevValues, dimension: dimensions.find(({ name }) => name === value) }));
    setTheme(null);
    trackFilterEvent(TRACK_EVENT.CHANGE_DRILL_DOWN_DIMENSION, { dimension: value });
  };

  useEffect(() => {
    if (searchParams.get('aspect')) dispatch(updateDrillDownTopic(null));
  }, [searchParams.get('aspect')]);

  useEffect(() => {
    selectedDrillDownTopic?.topic && setSearchParams((params) => (params.delete('aspect'), params));
  }, [selectedDrillDownTopic]);

  const { x } = useScroll(scrollRef);
  const scrollPercentage = (x / (scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth)) * 100;
  return (
    <Tabs.Root className="relative mt-2 rounded-t-xl border pt-4 text-xs font-medium" value={dimension?.name} onValueChange={changeDimension}>
      <Tabs.List className="flex">
        {chartDimension === SENTISUM_TAG_TYPES.SENTISUM_TAG && flags.commonPhrasesVersion !== 3 && (
          <Tabs.Trigger
            value="common_phrases"
            className="group relative shrink-0 px-5 pb-1.5 radix-state-active:border-b radix-state-active:border-b-indigo-600 radix-state-active:text-indigo-600 radix-state-inactive:text-gray-600"
          >
            <span className="p-2 group-radix-state-active:font-bold">Common Phrases</span>
            <span className="absolute ml-5 h-5 w-[1px] bg-gray-200 !pb-2"></span>
          </Tabs.Trigger>
        )}
        <div ref={scrollRef} className="scrollbar-hidden flex overflow-x-auto overflow-y-hidden pr-16">
          {dimensions?.map((dimension, index) => (
            <Tabs.Trigger
              value={dimension.name}
              className={cn('group shrink-0 px-4 pb-1.5 radix-state-inactive:text-gray-600', {
                'radix-state-active:border-b radix-state-active:border-b-indigo-600 radix-state-active:text-indigo-600': dimensions.length >= 1,
              })}
              key={index}
            >
              <span className="p-2 group-radix-state-active:font-bold">{dimension.display_name}</span>
            </Tabs.Trigger>
          ))}
        </div>
      </Tabs.List>
      <div className="from-white to-transparent">
        {scrollPercentage > 2 && <CarouselArrow side="left" className="ml-auto mr-2 pr-6 pt-1" onClick={() => scroll('left')} />}
        {scrollPercentage < 98 && <CarouselArrow side="right" className="right-0 w-8 rounded-t-xl pt-1" onClick={() => scroll('right')} />}
      </div>
    </Tabs.Root>
  );
};
