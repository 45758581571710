import { CustomSummayGraphTooltip } from 'src/components/Charts/LineCharts/SummaryGraph/CustomSummayGraphTooltip';
import { CustomDriverGraphTooltip } from 'src/ui/containers/SummaryTable/TableBody/CustomDriverGraphTooltip';

export const VerticalBarGraphTooltipContainer = ({ active, payload, selectedMetricType, chartComparator, isSurveyType }) => {
  if (!active || !payload || !payload.length) return null;
  if (!payload[0].payload.driverData)
    return (
      <CustomSummayGraphTooltip
        payload={payload}
        selectedMetricType={selectedMetricType}
        chartComparator={chartComparator}
        isSurveyType={isSurveyType}
      />
    );
  return (
    <CustomDriverGraphTooltip
      driver={payload[0].payload.driverData}
      isBarChart={true}
      selectedMetricType={selectedMetricType}
      chartComparator={chartComparator}
      isSurveyType={isSurveyType}
    />
  );
};
