export enum TRACK_EVENT {
  LOGIN = 'login',
  SIGNUP = 'signup',
  LOGOUT = 'logout',
  SUMMARY_PAGE_LOADED = 'Summary Page Loaded',
  DISCOVER_PAGE_LOADED = 'Discover Page Loaded',
  DASHBOARD_PAGE_LOADED = 'Dashboard Page Loaded',
  INTEGRATION_PAGE_LOADED = 'Integration Page Loaded',
  TEAM_PAGE_LOADED = 'Team Page Loaded',
  FILTER_SOURCE = 'Filter - Source',
  FILTER_DATE_RANGE = 'Filter - Date Range',
  FILTER_SEARCH_TEXT_IN_DISCOVER_PAGE = 'Filter - Search Text in Discover Page',
  OPEN_HELP_CENTER = 'Open Help Center',
  SUMMARY_TOPIC_TAG_SELECT = 'Summary Topic Tag Clicked',
  METRIC_CARD_CLICK = 'Metric Card Clicked',
  CREATED_METRIC_CARD = 'Created Metric Card',
  DELETED_METRIC_CARD = 'Deleted Metric Card',
  UPDATED_METRIC_CARD = 'Updated Metric Card',
  DUPLICATED_METRIC_CARD = 'Duplicated Metric Card',

  THEMES_SELECT = 'Theme Selected',
  TOPIC_SELECT = 'Topic Selected',
  ASPECT_SELECT = 'Aspect Selected',
  CONVERSATION_FILTER = 'Conversation Filter',
  OPEN_CONVERSATION = 'Conversation Opened',
  STAR_CONVERSATION = 'Conversation Star',
  EXPAND_METADATA = 'Conversation Metadata Opened',
  CONVERSATION_QA_TAG_UPDATE = 'Conversation QA Tag Updated',

  TAG_VIEW = 'Discover Page Tag view switched',
  CHART_DIMENSION = 'Filter - Chart Dimension',
  CHART_METRIC = 'Filter - Chart Metric',
  CHART_METRIC_CHANGE_COMPARATOR = 'Filter - Chart Metric Change Comparator',
  CHART_INTERVAL_BREAKDOWN = 'Filter - Chart Interval Breakdown',
  CHART_PREVIOUS_COMPARISON_INTERVAL = 'Filter - Chart Previous Comparison Interval',

  FILTER_ACTION = 'Filter - Metadata, SentiSum Tags',
  FILTER_BOOKMARK_VIEW = 'Filter View',
  RESTRICT_DIMENSION_TOGGLE = 'Restrict dimension toggle',

  APP_INTEGRATION = 'Application Integration',
  DATA_EXPORT = 'Data Export',

  DIY_TAG_OPERATIONS = 'DIY Tag Operations',

  PNG_DOWNLOAD = 'PNG Download',
  ONBOARDING_VERIFY_EMAIL = 'ONBOARDING_VERIFY_EMAIL',
  VERSION_UPDATE = 'Version Update',
  VERSION_UPDATE_CONFIRMED = 'Version Update Confirmed',

  CREATE_DASHBOARD = 'Create Dashboard',
  SELECTED_DASHBOARD = 'Selected Dashboard',
  RENAME_DASHBOARD = 'Rename Dashboard',
  RENAME_DASHBOARD_DESCRIPTION = 'Rename Dashboard Description',
  COPY_DASHBOARD_LINK = 'Copy Dashboard Link',
  DELETE_DASHBOARD = 'Delete Dashboard',
  DUPLICATE_DASHBOARD = 'Duplicate Dashboard',
  CREATE_REPORT = 'Create Report',
  EDIT_REPORT = 'Edit Report',
  DELETE_REPORT = 'Delete Report',
  DUPLICATE_REPORT = 'Duplicate Report',
  REORDER_REPORTS = 'Reorder Reports',
  SET_DASHBOARD_TO_HOME_PAGE = 'Set Dashboard To Home Page',
  REMOVE_DASHBOARD_FROM_HOME_PAGE = 'Remove Dashboard From Home Page',

  DIG_IN_SUMMARIZE = 'Dig In Summarize',
  DIG_IN_SUMMARIZE_COPY = 'Dig In Summarize Copy',
  DIG_IN_FAILED_SUMMARIZE = 'Dig In Failed Summarize',
  DIG_IN_FEEDBACK = 'Dig In Feedback',
  DIG_IN_REACTION = 'Dig In Reaction',

  USER_INVITE = 'User Invite',
  UPDATE_TEAM_INVITE_DOMAINS = 'Update Team Invite Domains',
  DELETE_TEAM_MEMBER = 'Delete Team Member',
  UPDATE_TEAM_MEMBER = 'Update Team Member',
  NO_SOURCES_PAGE_LOADED = 'No Sources Page Loaded',

  CREATE_REPORT_FROM_SUMMARY = 'Create Report From Summary',

  CREATE_CUSTOM_DIGEST = 'Create Custom Digest',
  UPDATE_CUSTOM_DIGEST = 'Update Custom Digest',
  DELETE_CUSTOM_DIGEST = 'Delete Custom Digest',
  SEND_CUSTOM_DIGEST_ONCE = 'Send Custom Digest Once',
  EXPORT_TO_PUBLIC_URL = 'Export To Public URL',

  CHANGE_DRILL_DOWN_DIMENSION = 'Change Drill Down Dimension',
  DRILL_DOWN_EXAND_TOGGLED = 'Drill Down Expand Toggled',
  CONVERSATION_AUDIO_PLAYED = 'Conversation Audio Played',

  TOGGLE_REPORT_VISUALIZATION = 'Toggle Report Visualization',

  CREATE_FOLDER = 'Create Folder',
  RENAME_FOLDER = 'Rename Folder',
  DELETE_FOLDER = 'Delete Folder',
  FOLDER_ORDER_CHANGE = 'Folder Order Change',
  DASHBOARD_ORDER_CHANGE = 'Dashboard Order Change',
  REMOVE_DASHBOARD_FROM_FOLDER = 'Remove Dashboard From Folder',

  YOUR_ALERTS_PAGE_LOADED = 'Your Alerts Page Loaded',
  YOUR_ALERTS_FILTER = 'Your Alerts Filter',
  YOUR_ALERTS_ALERT_CLICKED = 'Your Alerts Alert Clicked',
  INVESTIGATE_ALERT_LOADED = 'Investigate Alert Loaded',
  ALERT_SETUP_PAGE_LOADED = 'Alert Setup Page Loaded',
  ANOMALY_CREATED = 'Anomaly Created',
  ANOMALY_UPDATED = 'Anomaly Updated',
  ANOMALY_DELETED = 'Anomaly Deleted',
  ANOMALY_REACTION = 'Anomaly Reaction',
  ANOMALY_SUMMARY_COPY_RESULT = 'Anomaly Summary Copy Result',

  QUICK_INSIGHT_POPUP_SELECT = 'Quick Insight Popup Select',
  QUICK_SUMMARY_RESULT = 'Quick Summary Result',
  QUICK_SUMMARY_RESULT_COPY = 'Quick Summary Result Copy',
  QUICK_SUMMARY_FAILED = 'Quick Summary Failed',
  QUICK_INSIGHT_METRIC_SELECT = 'Quick Insight Metric Select',

  THEME_MANAGEMENT_PAGE_LOADED = 'Theme Management Page Loaded',
  CREATE_THEME = 'Create Theme',
  DUPLICATE_THEME = 'Duplicate Theme',
  UPDATE_THEME = 'Update Theme',
  DELETE_THEME = 'Delete Theme',
  DASHBOARD_DIMENSION_FILTER = 'Dashboard Dimension Filter',
  DIMENSION_FILTER = 'Dimension Filter',
  UNLOCK_DATE_RANGE = 'Unlock Date Range',
  LOCK_DATE_RANGE = 'Lock Date Range',
  EDIT_METRIC_CARD = 'EDIT_METRIC_CARD',
}
