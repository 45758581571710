import { Skeleton } from 'grommet';
import React from 'react';

export const MetricCardLoader = () => {
  return (
    <div className="flex min-w-48 flex-col justify-between space-y-1 rounded-xl p-5">
      <Skeleton width="80%" round="1rem" />
      <Skeleton width="50%" round="1rem" />
    </div>
  );
};
