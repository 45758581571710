import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import * as Select from 'src/components/Select';
import { updateChartDimensionType, updateDrillDownTopic } from 'Slices/filter';
import { FilterButton } from './FilterButton';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { resetConvdata } from 'Slices/timeseries';

export const DimensionDropdown = () => {
  const dispatch = useAppDispatch();
  const dimensions = useAppSelector((state) => state.filter.selectedSourceClientData.es_alias.dimensions);
  const dimension = useAppSelector((state) => state.filter.chartDimensionObject);
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const dimensionOptions = useMemo(() => dimensions.map(({ name, display_name }) => ({ value: name, label: display_name })), [dimensions]);
  const selectDimension = useCallback(
    async (value: string) => {
      trackFilterEvent(TRACK_EVENT.DIMENSION_FILTER, { dimension: value });
      if (selectedDrillDownTopic) {
        dispatch(resetConvdata());
        dispatch(updateDrillDownTopic(null));
      }
      dispatch(updateChartDimensionType(dimensions.find(({ name }) => name === value).name));
    },
    [dimensions, selectedDrillDownTopic]
  );
  if (dimensionOptions.length < 2) return <></>;
  return (
    <div className="relative">
      <Select.Root value={dimension.name} onValueChange={selectDimension}>
        <Select.Trigger asChild>
          <FilterButton data-testid="summary-dimension-dropdown">Dimension: {dimension.display_name}</FilterButton>
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={dimensionOptions} className="min-w-40" />
      </Select.Root>
    </div>
  );
};
