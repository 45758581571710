import { ConversationNavigator } from 'Pages/Discover/Inbox/ConversationNavigator';
import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'src/core/store';
import { cn } from 'Utils/TailwindUtils';
import { InboxDialogButton } from './InboxDialogButton';
import { HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2';
import { HiOutlineChat } from 'react-icons/hi';
import { QuickInsightBreadCrumb } from './QuickInsightBreadCrumb';

export interface InboxDialogHeaderProps {
  activeId: string;
  totalCount: number;
  selectedConvViewTabId: string;
  navigateConversation: (index: number) => void;
  activeConversationIndex: number;
  navigateToDiscover: () => void;
  setActiveId?: (value: string) => void;
  selectedDimension?: string;
}

export const InboxDialogHeader: FC<InboxDialogHeaderProps> = ({
  activeId,
  totalCount,
  selectedConvViewTabId,
  navigateConversation,
  activeConversationIndex,
  navigateToDiscover,
  setActiveId,
  selectedDimension,
}) => {
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  useEffect(() => selectedDrillDownTopic && setActiveId(null), [selectedDrillDownTopic]);
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <QuickInsightBreadCrumb activeId={activeId} setActiveId={setActiveId} selectedDimension={selectedDimension} />
        <div className="flex gap-1.5 text-xs font-normal text-gray-500">
          <span className="my-auto mb-1.5 inline-block h-[3px] w-[3px] rounded-full bg-black"></span>
          <span className="mt-0.5 flex items-end">{totalCount.toLocaleString()}</span>
        </div>
      </div>
      <div className="flex">
        {activeId ? (
          <div
            className="flex max-w-full cursor-pointer items-center justify-end"
            data-tooltip-id="tooltip"
            data-tooltip-content={'View all Conversations'}
            onClick={navigateToDiscover}
          >
            <HiOutlineChat className="size-5 stroke-[1.1px] text-gray-500 hover:text-black" />
          </div>
        ) : (
          <div className="flex max-w-full">
            <InboxDialogButton
              navigateToDiscover={navigateToDiscover}
              icon={HiOutlineChatBubbleLeftEllipsis}
              text="View All Conversations"
              className={cn('-my-[5px] mr-5 px-3.5 py-1.5 shadow-sm', { 'mr-0': activeId })}
            />
          </div>
        )}
        {!!activeId && selectedConvViewTabId && (
          <ConversationNavigator navigateConversation={navigateConversation} activeConversationIndex={activeConversationIndex} isPopup />
        )}
      </div>
    </div>
  );
};
