import React, { FC } from 'react';
import { Topic } from 'src/api/apiTypes/Summary';
import { Metric } from 'src/types/User';
import { IChartComparator } from 'src/types/Filter';
import { ReportType } from 'src/types/Report';
import { CHART_COMPARATOR_TYPES_ENUM } from 'Utils/enums/ChartComparatorTypes';
import { LANG_TYPES, TEXT_TYPES } from 'Utils/constants/languages';
import { CustomLegend } from '../../../components/Charts/BarCharts/GraphComponents';
import { HighestLowestHBG } from './HighestLowestHBG';
import { TopIncreaseDecreaseHBG } from './TopIncreaseDecreaseHBG';

export interface HorizontalBarGraphContainerProps {
  drivers: Topic[];
  chartMetricObject: Metric;
  chartComparator: IChartComparator;
  tableVisualization: ReportType;
  onClick?: (path: string) => void;
  showDateRange?: boolean;
  hideLabels?: boolean;
  isSurveyType?: boolean;
}

export const HorizontalBarGraphContainer: FC<HorizontalBarGraphContainerProps> = (props) => {
  const { tableVisualization, showDateRange, chartMetricObject, chartComparator, drivers } = props;
  if (tableVisualization === 'TP' || tableVisualization === 'TN' || tableVisualization === 'BC') return null;
  return (
    <div className="relative pl-2">
      {showDateRange && tableVisualization !== 'TI' && tableVisualization !== 'TD' && (
        <div className="sticky top-0 z-50 mb-2 h-4 w-full bg-white">
          <CustomLegend dateRange={drivers[0].current_interval.range} previousDateRange={drivers[0].previous_interval.range} />
        </div>
      )}
      {tableVisualization === 'TI' || tableVisualization === 'TD' ? (
        <TopIncreaseDecreaseHBG label={CHART_COMPARATOR_TYPES_ENUM[chartComparator][LANG_TYPES.EN][TEXT_TYPES.LONG]} {...props} />
      ) : tableVisualization === 'H' || tableVisualization === 'L' ? (
        <HighestLowestHBG label={chartMetricObject.display_name} {...props} />
      ) : null}
    </div>
  );
};
