import React, { FC } from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { cn } from 'Utils/TailwindUtils';

interface ProgressBarProps extends ProgressPrimitive.ProgressProps {
  indicatorClassName?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({ indicatorClassName, className, ...props }) => {
  const { value, max } = props;
  const valueAsPercentage = ((value || 0) / (max || 100)) * 100;
  return (
    <ProgressPrimitive.Root className={cn('overflow-hidden rounded-full bg-grey-300', className)} {...props}>
      <ProgressPrimitive.Indicator
        className={cn('h-full bg-grey-900 duration-300 ease-in-out', indicatorClassName)}
        style={{ width: `${valueAsPercentage}%` }}
      />
    </ProgressPrimitive.Root>
  );
};
