import { MOMENT_CHART_TOOLTIP_DATE_FORMAT, getChartDateLabel } from 'Utils/chartsV2/graphFunctions';
import { createElement, useCallback, useMemo } from 'react';
import { TEXT_TYPES, LANG_TYPES } from 'Constants/languages';
import { textOperations, checkConditions, getColorOverDataChange } from 'Utils';
import { Box, Text } from 'grommet';
import { METRICS_VALUE_META } from 'Enums/ChartMetadata';
import { DownRed, UpGreen, DottedLineLegend, SolidLineLegend } from 'src/static/images';

import styled from 'styled-components';
import { PERCENT_CHANGE_COMPARATOR_META } from 'src/core/utils/enums/ChartMetadata';
import { useLDFlags } from 'Hooks/useLaunchDakly';

const TooltipContainer = styled(Box)({
  border: '1.05px solid #e0e0e0',
  borderRadius: '12px',
  boxShadow: '0px 4.2px 10.5px rgba(0, 0, 0, 0.12)',
  backgroundColor: '#ffffffb8',
  backdropFilter: 'blur(4px)',
});

export const CustomSummayGraphTooltip = (props) => {
  const { payload, selectedMetricType, isSurveyType, chartComparator } = props;
  const { surveySourceChangeColor } = useLDFlags();
  const getValueText = (valueData) => {
    let { prefixText, value, postfixText } = checkConditions.getChartValueDisplayProperty(valueData, METRICS_VALUE_META[selectedMetricType]);
    const text = prefixText + value + postfixText;
    return text;
  };

  let dataMeta;
  let previousDataMeta;
  const dataObject = payload.find((chartPayload) => {
    if (chartPayload.dataKey === 'data') {
      dataMeta = chartPayload.payload.dataMeta;
      return true;
    }
    return false;
  });
  const previousDataObject = payload.find(({ dataKey }) => dataKey === 'previousData');
  previousDataMeta = previousDataObject?.payload.previousDataMeta;

  const title = useMemo(() => textOperations.getTitleName(selectedMetricType, LANG_TYPES.EN, TEXT_TYPES.ALIAS), [selectedMetricType]);
  const graphCurrentColor = getColorOverDataChange(selectedMetricType, dataMeta?.metric?.change, isSurveyType && surveySourceChangeColor);
  const changeIcon = createElement(dataMeta?.metric?.change < 0 ? DownRed : UpGreen, { stroke: graphCurrentColor, fill: graphCurrentColor });
  const changeLegend = <SolidLineLegend className="text-indigo-600" />;
  const changedText = useCallback(() => {
    const { value, postfixText } = checkConditions.getChartValueDisplayProperty(
      dataMeta?.metric?.change,
      PERCENT_CHANGE_COMPARATOR_META[chartComparator][selectedMetricType],
      true
    );
    return Math.abs(value) + postfixText;
  }, [selectedMetricType, chartComparator, dataMeta]);
  return (
    <TooltipContainer round="xsmall" pad="small" gap="xsmall" elevation="small">
      <Box>
        <Text size="xsmall">
          <b>{title}</b>
        </Text>
      </Box>
      {dataObject && dataMeta && (
        <>
          {previousDataObject?.payload?.previousData ? (
            <Box direction="column">
              <Box direction="row" align="center">
                {changeLegend}&ensp;
                <Text size="xsmall" className="capitalize" color={'gray-1'}>
                  {getChartDateLabel(dataMeta.range, dataMeta.interval, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}
                </Text>
              </Box>
              <Box direction="row" align="center">
                &emsp;&emsp;
                <Text size="small" color={'gray-1'}>
                  <b>{getValueText(dataObject.payload.data)}</b>
                </Text>
                &ensp;
                {changeIcon}
                &ensp;
                <Text size="14px" color={graphCurrentColor}>
                  {changedText()}
                </Text>
                &ensp;
                <Text size="xsmall" color="#828282">
                  from previous period
                </Text>
              </Box>
            </Box>
          ) : (
            <Box direction="column" gap="xsmall" align="start">
              <Box direction="row" align="center">
                {changeLegend}&ensp;
                <Text size="xsmall" className="capitalize" color={'gray-1'}>
                  {getChartDateLabel(dataMeta.range, dataMeta.interval, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}
                </Text>
              </Box>
              <Text size="small">
                <b>{getValueText(dataObject.payload.data)}</b>
              </Text>
            </Box>
          )}
        </>
      )}
      {previousDataObject?.payload?.previousData ? (
        <Box direction="column" align="start">
          <Box direction="row" align="center">
            <DottedLineLegend />
            &ensp;
            <Text size="xsmall" className="capitalize" color={'gray-1'}>
              {getChartDateLabel(previousDataMeta.range, previousDataMeta.interval, MOMENT_CHART_TOOLTIP_DATE_FORMAT)}
            </Text>
          </Box>

          <Text size="small" color={'gray-1'}>
            &emsp; &emsp;
            <b>{getValueText(dataObject?.payload?.previousData ?? previousDataObject.payload.previousData)}</b>
          </Text>
        </Box>
      ) : (
        ''
      )}
    </TooltipContainer>
  );
};
