import { cn } from 'Utils/TailwindUtils';
import { RadixIconDropdown } from 'src/components/Dropdown';
import { FiTrash2 } from 'react-icons/fi';
import { HiOutlineFaceFrown, HiOutlineFaceSmile } from 'react-icons/hi2';
import { Neutral } from 'src/static/images';

interface EditableSentimentTagProps {
  name: string;
  type: string;
  sentiment: string;
  onAction: (name: string, action: string) => void;
  key: React.Key;
}

const EditableSentimentTag = ({ name, type, sentiment, onAction, ...rest }: EditableSentimentTagProps) => {
  const surveyOptions = [
    {
      label: 'Mark as Positive',
      icon: HiOutlineFaceSmile,
      value: 'positive',
      onClick: () => onAction(name, 'positive'),
      iconProps: {
        className: 'text-green-600 h-5 w-5',
      },
    },
    {
      label: 'Mark as Neutral',
      value: 'neutral',
      icon: Neutral,
      onClick: () => onAction(name, 'neutral'),
      iconProps: {
        className: 'h-5 w-5',
      },
    },
    {
      label: 'Mark as Negative',
      value: 'negative',
      icon: HiOutlineFaceFrown,
      onClick: () => onAction(name, 'negative'),
      iconProps: {
        className: 'text-red-600 h-5 w-5',
      },
    },
    {
      label: 'Remove',
      value: 'remove',
      icon: FiTrash2,
      onClick: () => onAction(name, 'remove'),
    },
  ];
  const nonSurveyOptions = [
    {
      label: 'Remove',
      value: 'remove',
      icon: FiTrash2,
      onClick: () => onAction(name, 'remove'),
    },
  ];
  return (
    <>
      <RadixIconDropdown
        options={type === 'survey' ? surveyOptions.filter((option) => option.value !== sentiment) : nonSurveyOptions}
        className="min-w-36"
        {...rest}
      >
        <button
          className={cn('rounded-md px-3 py-0.5 text-sm capitalize', {
            'bg-neutral-200 text-gray-600': sentiment === 'neutral',
            'bg-green-130 text-green-590': sentiment === 'positive',
            'bg-red-270 text-red-470': sentiment === 'negative',
            'bg-blue-270 text-blue-570': type !== 'survey',
          })}
        >
          {name}
        </button>
      </RadixIconDropdown>
    </>
  );
};

export default EditableSentimentTag;
