import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import * as Select from 'src/components/Select';
import { updateChartMetricSelector, updateDrillDownTopic } from 'Slices/filter';
import { FilterButton } from './FilterButton';
import { resetConvdata } from 'Slices/timeseries';

export const MetricsDropdown = () => {
  const dispatch = useAppDispatch();
  const metrics = useAppSelector((state) => state.filter.selectedSourceClientData.es_alias.metrics);
  const metric = useAppSelector((state) => state.filter.chartMetricObject);
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const metricOptions = useMemo(() => metrics.map(({ name, display_name }) => ({ value: name, label: display_name })), [metrics]);
  const selectDimension = useCallback(
    (value: string) => {
      if (selectedDrillDownTopic) {
        dispatch(resetConvdata());
        dispatch(updateDrillDownTopic(null));
      }
      dispatch(updateChartMetricSelector(metrics.find(({ name }) => name === value).name));
    },
    [metrics, selectedDrillDownTopic]
  );
  if (metricOptions.length < 2) return <></>;
  return (
    <div className="relative">
      <Select.Root value={metric.name} onValueChange={selectDimension}>
        <Select.Trigger asChild>
          <FilterButton data-testid="summary-metric-dropdown">Metric: {metric.display_name}</FilterButton>
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={metricOptions} className="min-w-40" />
      </Select.Root>
    </div>
  );
};
