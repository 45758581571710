import { cn } from 'Utils/TailwindUtils';
import React, { FC } from 'react';
import { HiSparkles } from 'react-icons/hi';
import { buttonCVA } from 'src/components/Button';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';
import { Markdown } from 'src/components/Markdown';
import { CopyQuickSummaryAnswer } from './CopyQuickSummaryAnswer';

export const QuickSummaryResult: FC<{ navigateToDiscover: () => void }> = ({ navigateToDiscover }) => {
  const [{ summary }] = useQuickSummaryDiginContext();

  return (
    <div className="mb-5 mt-5 flex flex-col overflow-y-auto" data-testid="quick-insight-summary-result">
      <Markdown className="max-w-fit text-sm leading-relaxed text-gray-510 prose-h1:text-xl">{summary.answer}</Markdown>
      <div className="absolute right-10 top-24">
        <CopyQuickSummaryAnswer />
      </div>
      <div>
        <div
          className="my-3 mr-2 flex grow justify-between gap-3 rounded-full border border-grey-300 bg-white p-1 shadow-lg"
          role="button"
          onClick={navigateToDiscover}
        >
          <span className="ml-3 flex items-center text-sm text-gray-500">Ask more questions</span>
          <span className={cn(buttonCVA({ variant: 'dark' }), 'flex shrink-0 items-center justify-center gap-2 rounded-full text-xs')}>
            <HiSparkles />
            Dig In
          </span>
        </div>
      </div>
    </div>
  );
};
