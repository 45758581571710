import React, { FC, useMemo, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import * as Select from 'src/components/Dropdown/DropdownMenu/RadixDropdownMenu';
import { Theme } from 'src/types/theme';

interface SelectThemeProps {
  theme: string;
  selectTheme: (value: string) => void;
  themes: Theme[];
  dimensionName: string;
}

export const SelectTheme: FC<SelectThemeProps> = ({ themes, theme, selectTheme, dimensionName }) => {
  const [open, setOpen] = useState(false);
  const themeNameList = themes.filter((theme) => theme.dimension_mapping && theme.dimension_mapping[dimensionName]).map((theme) => theme.name);
  const themeOptions = useMemo(() => {
    const themeOptions = themeNameList.map((theme) => ({ value: theme, label: theme })).sort((a, b) => a.label.localeCompare(b.label));
    themeOptions.unshift({ value: '', label: 'All Themes' });
    return themeOptions;
  }, [themes, dimensionName]);
  return (
    <Select.Root open={open} onOpenChange={setOpen}>
      <Select.Trigger className="ml-3.5 flex items-center gap-0.5 rounded-xl border border-gray-200 bg-gray-100 px-3 py-1 text-xs font-semibold capitalize text-gray-600">
        {theme || 'All Themes'}
        <HiChevronDown className="h-4 w-4" />
      </Select.Trigger>
      <Select.RadixDropdownMenu
        options={themeOptions}
        className="max-h-36"
        searchPlaceholder="Search Themes"
        menuOpen={open}
        onItemClick={selectTheme}
        optionClassName="capitalize"
      />
    </Select.Root>
  );
};
