import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { DateRangePickerRoot } from 'src/components/DateRangePicker';
import { DateRangeLabel } from 'src/components/Label';
import moment from 'moment';
import { updateChartPreviousComparisonIntervalSelector } from 'Slices/filter';
import getPreviousDateIntervals from 'Constants/previousDateRangeIntervals';
import { IDateFilterData } from 'Slices/types/filter';

export const PrevoiusDateRange = () => {
  const dispatch = useAppDispatch();
  const { previousDateRange, selectedDateField, previous_interval, dateRange } = useAppSelector((state) => state.filter.filters);
  const dateRangeBound = useAppSelector((state) => state.filter.dateRangeBounds).find(({ date_field }) => date_field === selectedDateField);

  const dateRangeBounds = useMemo(() => {
    if (!dateRangeBound) return { start: null, end: null };
    const { start, end } = dateRangeBound;
    return { start: moment(start).startOf('day').toDate(), end: moment(end).endOf('day').toDate() };
  }, [dateRangeBound]);

  const setPreviousDateRange = useCallback(
    (dateFilterData: IDateFilterData) => dispatch(updateChartPreviousComparisonIntervalSelector(dateFilterData)),
    []
  );
  const dateIntervals = useMemo(() => getPreviousDateIntervals(dateRange), [dateRange]);

  if (previousDateRange && previous_interval) {
    return (
      <DateRangePickerRoot
        dateRange={previousDateRange}
        dateRangeBounds={dateRangeBounds}
        setDateRange={setPreviousDateRange}
        dateIntervals={dateIntervals}
        interval={previous_interval}
        defaultIntervalKey="default"
        className="flex items-center gap-1 bg-white p-3 pl-0 font-medium"
      >
        <DateRangeLabel {...previousDateRange} prefix="Comparing To: " />
      </DateRangePickerRoot>
    );
  }
  return <></>;
};
