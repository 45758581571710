export const INTEGRATION_DATA_SOURCE_TYPES = {
  ZENDESK: 'zendesk',
  INTERCOM: 'intercom',
  TRUSTPILOT: 'trustpilot',
  DIXA: 'dixa',
  FACEBOOK: 'facebook',
};

export const INTEGRATION_DATA_SOURCE_PROPERTIES = {
  ID: 'ID',
};

export const INTEGRATION_DATA_SOURCE_PROPERTY_LINK = {
  [INTEGRATION_DATA_SOURCE_TYPES.ZENDESK]: {
    [INTEGRATION_DATA_SOURCE_PROPERTIES.ID]: {
      LINK: 'https://{0}.zendesk.com/agent/tickets/{1}',
    },
  },
  [INTEGRATION_DATA_SOURCE_TYPES.INTERCOM]: {
    [INTEGRATION_DATA_SOURCE_PROPERTIES.ID]: {
      LINK: 'https://app.intercom.com/a/apps/imkqk2yb/inbox/inbox/2983933/conversations/1388',
    },
  },
  [INTEGRATION_DATA_SOURCE_TYPES.TRUSTPILOT]: {
    [INTEGRATION_DATA_SOURCE_PROPERTIES.ID]: {
      LINK: 'https://www.trustpilot.com/reviews/{0}',
    },
  },
  [INTEGRATION_DATA_SOURCE_TYPES.DIXA]: {
    [INTEGRATION_DATA_SOURCE_PROPERTIES.ID]: {
      LINK: 'https://{0}.dixa.com/conversation/{1}',
    },
  },
};
