import React, { FC, useMemo, useState } from 'react';
import { useQuickInsight } from '../QuickInsightContainer';
import { useQuery } from '@tanstack/react-query';
import { getSummaryOverviewGraphData } from 'src/api/Summary';
import { SummaryGraph } from 'src/components/Charts/LineCharts';
import { formChartValueOverTime, getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { LANG_TYPES, TEXT_TYPES } from 'Utils/constants/languages';
import { textOperations } from 'Utils';
import { METRICS_VALUE_META } from 'Utils/enums/ChartMetadata';
import { OverviewGraphLoader } from './OverviewGraphLoader';
import { VerticalBarGraphSection } from 'src/components/Charts/BarCharts/BarGraphCards/VerticalBarGraph';
import { SURVEY_TYPES } from 'Utils/enums/SourceModelTypes';
import { getOverTimeOptions } from 'Utils/constants/VisualizationOptions';
import { IconToggle } from 'src/components/ToggleGroup';
import { InsightOverviewDataProps } from '../InsightOverviewData';

const chartProps = {
  margin: { top: 0, right: 5, left: -9, bottom: 0 },
};

const tooltipProps = {
  allowEscapeViewBox: { x: false, y: true },
  offset: -20,
};

type QuickInsightOverviewGraphProps = Pick<InsightOverviewDataProps, 'sourceType'>;

export const QuickInsightOverviewGraph: FC<QuickInsightOverviewGraphProps> = ({ sourceType }) => {
  const [selectedVisualization, setSelectedVisualization] = useState('LG');
  const { selectedMetric, state, selectedDimension, topicPayload, chartComparator, dimension } = useQuickInsight();
  const isSurveyType = useMemo(() => SURVEY_TYPES.includes(sourceType), [sourceType]);
  let filterDimension = {};
  if (topicPayload.hasOwnProperty('dimension_values')) {
    filterDimension = {
      filter_dimension: {
        filter_value: topicPayload.dimension_values,
        dimension: dimension,
      },
    };
  }
  let updatedPayload = topicPayload.hasOwnProperty('dimension_values') ? filterDimension : topicPayload;
  const useQuickInsightOverviewQuery = useQuery({
    queryKey: ['QuickInsightOverview', selectedMetric?.name, state, selectedDimension],
    queryFn: () => getSummaryOverviewGraphData({ ...updatedPayload, metric: selectedMetric }, state),
  });
  if (useQuickInsightOverviewQuery.isLoading) return <OverviewGraphLoader />;
  const selectedMetricData = useQuickInsightOverviewQuery?.data?.data[selectedMetric?.name];
  const overAllMetricObject = getMetricValueAndPercentChange(selectedMetricData?.current_interval?.metrics, selectedMetric?.name, chartComparator);
  const valueOverTime = formChartValueOverTime(selectedMetricData, selectedMetric?.name, chartComparator);
  const chartYAxisMetaData = () => ({
    show: true,
    label: textOperations.getTitleName(selectedMetric?.name, LANG_TYPES.EN, TEXT_TYPES.ALIAS),
    isDecimal: METRICS_VALUE_META[selectedMetric?.name]?.IS_DECIMAL,
  });
  let overTimeData = [];
  valueOverTime.data.forEach((item) => {
    overTimeData.push({ ...item, name: item.date });
  });
  return (
    <div className="mr-3 pb-3 pt-5 blur-none">
      {!isSurveyType && (
        <div className="flex justify-end">
          <IconToggle
            options={getOverTimeOptions(true)}
            onValueChange={(value) => setSelectedVisualization(value)}
            optionClassName="px-3 py-1.5 bg-gray-130 text-gray-600 radix-state-on:bg-indigo-100 radix-state-on:text-indigo-600 radix-state-on:ring-1 radix-state-on:ring-indigo-150 first:rounded-l-lg last:rounded-r-lg"
            aria-label="Visualization Toggle"
            className="gap-px rounded-lg border bg-gray-300"
            value={selectedVisualization}
          />
        </div>
      )}
      {selectedVisualization === 'LG' ? (
        <SummaryGraph
          height={268}
          selectedMetricType={selectedMetric?.name}
          valueOverTime={valueOverTime}
          changeValue={overAllMetricObject?.change}
          YAxisMeta={chartYAxisMetaData()}
          chartComparator={chartComparator}
          chartProps={chartProps}
          tooltipProps={tooltipProps}
          isSurveyType={isSurveyType}
        />
      ) : (
        <div className="h-[16.75rem]">
          <VerticalBarGraphSection data={overTimeData} type="overTime" selectedMetricType={selectedMetric?.name} chartComparator={chartComparator} />
        </div>
      )}
    </div>
  );
};
