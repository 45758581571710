import { BarChart, Tooltip, ResponsiveContainer } from 'recharts';
import { FC, PropsWithChildren, useMemo } from 'react';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { TickValues } from '../../GraphComponents/TickValues';
import { IChartComparator } from 'src/types/Filter';
import { Topic } from 'src/api/apiTypes/Summary';
import { SummaryBarGraphTooltip, customYAxis } from 'src/components/Charts/BarCharts/GraphComponents';

export interface IChartData {
  name: string;
  driverData: Topic;
  data?: number;
  previousData?: number;
  dataLabel?: string;
  color?: string;
  dataValue?: number;
}

interface HorizontalBarGraphCardProps {
  data: IChartData[];
  selectedMetricType: SOURCE_TYPES;
  chartComparator: IChartComparator;
  onClick?: (name: string) => void;
  isSurveyType?: boolean;
}

export const HorizontalBarGraphCard: FC<PropsWithChildren<HorizontalBarGraphCardProps>> = ({
  data,
  chartComparator,
  selectedMetricType,
  children,
  onClick,
  isSurveyType,
}) => {
  const yAxisData = useMemo(
    () => ({
      dataKey: 'name',
      type: 'category' as const,
      width: 140,
      tick: (props) => {
        const { x, y, payload } = props;
        return <TickValues x={x} y={y} value={payload.value} isHorizontalBarGraph onClick={onClick} />;
      },
    }),
    []
  );
  return (
    <ResponsiveContainer width="100%" height={data.length === 1 ? data.length * 100 : data.length * 70} className="horizontal-bar-graph">
      <BarChart data={data} layout="vertical" barGap={-8} reverseStackOrder margin={{ top: 20, bottom: 10 }}>
        {customYAxis({ yAxisData })}
        <Tooltip
          content={(props) => {
            return (
              <SummaryBarGraphTooltip
                active={props.active}
                payload={props.payload}
                selectedMetricType={selectedMetricType}
                chartComparator={chartComparator}
                isSurveyType={isSurveyType}
              />
            );
          }}
          cursor={false}
        />
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
};
