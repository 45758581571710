import { FC, MouseEvent, useEffect, useState } from 'react';
import { Box, Text as GrommetText } from 'grommet';
import moment from 'moment';
import { Star } from 'grommet-icons';
import Tags from './Tags';
import { cn } from 'Utils/TailwindUtils';
import { ExtendedConversationData } from '../types';
import { ChatHighlighter } from '../ChatHighlighter';
import { useLDFlags } from 'Hooks/useLaunchDakly';

interface InboxCardProps extends ExtendedConversationData {
  onRead: (type: MouseEvent) => void;
  onStar: (isStar: boolean) => void;
  active: boolean;
  index: number;
}

const InboxCard: FC<InboxCardProps> = (props) => {
  const { id, messages = [], isRead: initialRead, isStarred: initialStarred, date, tags, type, onRead, onStar, active } = props;
  const { showMetadataInHeader } = useLDFlags();
  const [isStar, setIsStar] = useState(initialStarred);
  const [isRead, setIsRead] = useState(initialRead);
  const messageCount = useLDFlags().messageCount ?? 1;

  const previewMessage =
    messages
      .filter(
        ({ author, commentType }) =>
          author.role.toLocaleLowerCase() === 'visitor' || !author.role || ['callSummary', 'ticketSummary'].includes(commentType)
      )
      .slice(0, messageCount)
      .map((message) => message.originalMessage ?? message.message)
      .join(' ') ||
    (messages[0]?.originalMessage ?? messages[0]?.message);

  const onStarClick = (e) => {
    e.stopPropagation();
    setIsStar(!isStar);
    onStar(!isStar);
  };

  const onCardClick = (event) => {
    if (!isRead) setIsRead(true);
    onRead(event.type);
  };

  useEffect(() => {
    if (active) setIsRead(true);
  }, [active]);
  return (
    <Box
      border={{ color: 'transparent', size: 'medium', side: 'left' }}
      onClick={!active ? onCardClick : null}
      pad={{ vertical: 'small', horizontal: 'medium' }}
      direction="row"
      justify="between"
      focusIndicator={false}
      width={'100%'}
      background={isRead ? { color: 'dark-gray-1' } : 'white'}
      data-testid="inbox-card"
    >
      <div className="flex w-full flex-col justify-center gap-3">
        <Box gap={'xxsmall'}>
          <div className="flex items-center justify-between gap-2">
            <Box direction={'row'} align={'center'} gap="small">
              {showMetadataInHeader === 'Store Name' && props?.metadata?.metadata?.['Store Name'] ? (
                <GrommetText color="#828282" truncate size={'small'} weight={500}>
                  Store Name: {props?.metadata?.metadata?.['Store Name']}
                </GrommetText>
              ) : (
                <GrommetText color="#828282" truncate size={'small'} weight={500}>
                  Ticket ID: {id}
                </GrommetText>
              )}
              {showMetadataInHeader === 'platform' && props?.metadata?.metadata?.platform && (
                <div
                  className={cn(
                    'flex shrink-0 items-center rounded-xl border border-grey-200 px-3 pt-0.5 text-sm font-medium capitalize text-grey-500',
                    isRead ? 'bg-white' : 'bg-grey-25'
                  )}
                  data-tooltip-id="tooltip"
                  data-tooltip-content={props.metadata.metadata.platform as string}
                  data-tooltip-hidden={(props.metadata.metadata.platform as string).length < 15}
                >
                  {(props.metadata.metadata.platform as string).length > 15 ? (
                    <>{(props.metadata.metadata.platform as string).slice(0, 13)}..</>
                  ) : (
                    <>{props.metadata.metadata.platform}</>
                  )}
                </div>
              )}
              {props?.metadata?.channel && (
                <div
                  className={cn(
                    'flex shrink-0 items-center rounded-xl border border-grey-200 px-3 pt-0.5 text-sm font-medium capitalize text-grey-500',
                    isRead ? 'bg-white' : 'bg-grey-25'
                  )}
                >
                  {props.metadata.channel}
                </div>
              )}
            </Box>
            <Box direction={'row'} align={'center'} gap="xsmall">
              <GrommetText size={'small'} color="#828282" weight={500} truncate>
                {moment.utc(date).local().format('hh:mm A')}
              </GrommetText>
              <Star onClick={onStarClick} color={isStar ? 'gold' : 'light-6'} style={{ cursor: 'pointer' }} />
            </Box>
          </div>
        </Box>
        <div className="my-1.5">
          <ChatHighlighter
            message={previewMessage.toLocaleUpperCase() === 'NO VISITOR COMMENTS' ? '' : previewMessage}
            className="line-clamp-2 text-sm font-normal"
          />
        </div>
        <Tags tags={tags} type={type} />
      </div>
    </Box>
  );
};

export default InboxCard;
