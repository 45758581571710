import { useAppDispatch, useAppSelector } from 'src/core/store';
import { EXPORT_DATA_TYPE } from './DataExportEnums';
import { Box, Text } from 'grommet';
import { useState, useCallback, useEffect } from 'react';
import Fuse from 'fuse.js';
import { updateConversationsMetadataFieldList } from 'Slices/timeseries';
import { startToDownloadExportData } from 'Slices/dataExport';
import { BlockButtonSmall, Button } from 'src/components/Button';
import { Spinner } from 'src/components/Spinner';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { FiInfo, FiSearch } from 'react-icons/fi';
import { cn } from 'Utils/TailwindUtils';
import { CheckBox } from 'src/components/Input';
import type { IConversationsMetadataFieldList } from 'Slices/types/timeseries';
import { cloneDeep } from 'lodash';
import { BreadCrumb } from 'src/components/BreadCrumbs/types';
import { Breadcrumbs } from 'src/components/BreadCrumbs';
import { HiOutlineInformationCircle } from 'react-icons/hi';

const ExportConversationConfiguration = () => {
  const dispatch = useAppDispatch();
  const { totalConversationCount, conversationsMetadataFieldList, fetchConversationsMetadataFieldsSource, fetchConversationsMetadataFieldsStatus } =
    useAppSelector((state) => state.timeseries);
  const filters = useAppSelector((state) => state.filter.filters);
  const [searchString, setSearchString] = useState('');
  const [metadataList, setMetadataList] = useState<IConversationsMetadataFieldList[]>();
  const [noSearchResultFound, setNoSearchResultFound] = useState(false);

  const fuse = new Fuse(conversationsMetadataFieldList, {
    keys: ['label'],
  });
  useEffect(() => {
    setNoSearchResultFound(false);
    setMetadataList(conversationsMetadataFieldList);
  }, [conversationsMetadataFieldList]);

  const handleSelect = useCallback(
    (index: number, checked: boolean) => {
      let option = { ...conversationsMetadataFieldList[index] };
      const options = [...conversationsMetadataFieldList];
      option.checked = checked;
      options.splice(index, 1, option);
      dispatch(updateConversationsMetadataFieldList(options));
    },
    [conversationsMetadataFieldList]
  );

  const handleSelectAll = useCallback(
    (checked: boolean) => dispatch(updateConversationsMetadataFieldList(conversationsMetadataFieldList.map((option) => ({ ...option, checked })))),
    [conversationsMetadataFieldList]
  );

  const doSearch = (searchText: string) => {
    searchText = searchText.trim();
    setSearchString(searchText);
    if (searchText) {
      const results = fuse.search(searchText).map((result) => ({
        path: result.item.path,
        field_name: result.item.field_name,
        label: result.item.label,
        checked: result.item.checked,
        index: result.item.index,
      }));
      setMetadataList(results);
      if (results.length > 0) setNoSearchResultFound(false);
      else setNoSearchResultFound(true);
    } else {
      setNoSearchResultFound(false);
      setMetadataList(conversationsMetadataFieldList);
    }
  };
  const showSpinner = () => {
    return (
      <Box align={'center'} justify={'center'} height={'10vh'}>
        <Spinner />
      </Box>
    );
  };
  const showMetadataList = () => {
    if (
      !!metadataList &&
      filters?.source &&
      fetchConversationsMetadataFieldsSource &&
      ['fulfilled'].includes(fetchConversationsMetadataFieldsStatus) &&
      filters?.source === fetchConversationsMetadataFieldsSource
    ) {
      if (conversationsMetadataFieldList.length > 0) {
        if (metadataList.length > 0)
          return (
            <div>
              <div className="hover:bg-sky-1000">
                <CheckBox
                  onChange={(value) => handleSelectAll(value as boolean)}
                  checked={metadataList.every((option) => option.checked)}
                  indeterminate={metadataList.some((option) => option.checked) && !metadataList.every((option) => option.checked)}
                  className="my-3 ml-3"
                  label={<span className="text-sm capitalize">Select All</span>}
                />
              </div>
              {metadataList.map(({ label, checked, index }, dataIndex) => (
                <div className={cn('hover:bg-sky-1000', { 'flex gap-3 text-sm': label === 'comments' })} key={index}>
                  <CheckBox
                    onChange={(value) => {
                      const metadataPropsList = cloneDeep(metadataList);
                      metadataPropsList[dataIndex].checked = value as boolean;
                      setMetadataList(metadataPropsList);
                      handleSelect(index, value as boolean);
                    }}
                    checked={checked}
                    className="my-3 ml-3"
                    label={<span className="text-sm capitalize">{label}</span>}
                  />
                  {label === 'comments' && (
                    <div className="flex items-center">
                      <HiOutlineInformationCircle
                        className="size-4 stroke-[1.8px] text-grey-600"
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Excel supports a maximum of 32,767 characters. Content beyond this limit will be excluded from the CSV. Please refer to the dashboard for the full content."
                        data-tooltip-class-name="max-w-[20rem]"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        else if (noSearchResultFound)
          return (
            <Box align={'center'} justify={'center'} height={'10vh'}>
              <Text size={'small'}> No result found.</Text>
            </Box>
          );
        return showSpinner();
      }
      if (['fulfilled'].includes(fetchConversationsMetadataFieldsStatus)) {
        return (
          <Box align={'center'} justify={'center'} height={'10vh'}>
            <Text size={'small'}> No extra fields.</Text>
          </Box>
        );
      }
    } else {
      if (['rejected'].includes(fetchConversationsMetadataFieldsStatus)) {
        return (
          <Box align={'center'} justify={'center'} height={'10vh'}>
            <Text size={'small'}> Failed to load fields.</Text>
          </Box>
        );
      }

      return showSpinner();
    }
  };
  const downloadConversationDataExport = () => {
    dispatch(startToDownloadExportData());
  };
  const breadcrumbs: BreadCrumb[] = [{ label: 'Export' }, { label: 'Conversation Data' }];
  return (
    <Box height={'100%'} width={'100%'} style={{ display: 'block' }}>
      <Box direction={'row'} margin={{ bottom: 'xsmall' }} align={'start'} width={'100%'}>
        <Text size={'small'} truncate color={'#000'}>
          Download CSV Results For {totalConversationCount.toLocaleString()} Conversations
        </Text>
      </Box>
      <div className="font-normal text-gray-800">
        <Breadcrumbs crumbs={breadcrumbs} crumbClassName="text-xs" className="gap-1" />
      </div>
      <div className="my-2 flex gap-3 rounded-lg bg-indigo-25 p-3 text-xs">
        <FiInfo className="h-5 w-5" />
        <span className="font-normal">
          IDs, date and Sentisum Tags are automatically included, please select any additional fields you would like.
        </span>
      </div>

      <Text size={'18px'} weight={'bold'}>
        Choose data columns:
      </Text>
      <div className="flex justify-between py-2">
        <div className="flex w-96 items-center gap-2 rounded-xl border p-2 text-base">
          <FiSearch />
          <input
            placeholder="Search"
            value={searchString}
            onChange={(e) => doSearch(e.target.value)}
            autoFocus
            className="grow text-xs text-blue-1100 placeholder-gray-850"
          />
          <BlockButtonSmall icon={IoCloseCircleOutline} className={cn('!p-0', { hidden: !searchString })} onClick={() => doSearch('')} />
        </div>
        <Button label="Download CSV" variant="primary" onClick={downloadConversationDataExport} />
      </div>
      {showMetadataList()}
    </Box>
  );
};
export const DataExportConfigurationComponent = ({}) => {
  const { dataExportType } = useAppSelector((state) => state.dataexport);
  const showConfigurationComponent = () => {
    if (dataExportType === EXPORT_DATA_TYPE.CONVERSATION_DATA) {
      return <ExportConversationConfiguration />;
    }
    return null;
  };
  return <>{showConfigurationComponent()}</>;
};
