import Lottie from 'lottie-react';
import React, { useCallback, useState } from 'react';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { useCopyToClipboard } from 'react-use';
import greenCheckAnimation from 'Static/animations/green-check.json';
import { TRACK_EVENT, trackDashboardEvent, trackFilterEvent } from 'src/core/trackEvent';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';

export const CopyQuickSummaryAnswer = () => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const [showCopiedAnimation, setShowCopiedAnimation] = useState(false);
  const [digInQuickSummaryState] = useQuickSummaryDiginContext();
  const copySummary = useCallback(() => {
    if (!digInQuickSummaryState.summary) return;
    copyToClipboard(digInQuickSummaryState.summary.answer);
    setShowCopiedAnimation(true);
    trackDashboardEvent(TRACK_EVENT.QUICK_SUMMARY_RESULT_COPY, { summaryText: digInQuickSummaryState.summary.answer });
  }, []);

  return (
    <div className="shrink-0">
      {showCopiedAnimation ? (
        <Lottie animationData={greenCheckAnimation} loop={false} className="h-8 w-8" onComplete={() => setShowCopiedAnimation(false)} />
      ) : (
        <button
          className="grid h-8 w-8 place-content-center rounded-full border bg-white hover:shadow-md"
          data-tooltip-id="tooltip"
          data-tooltip-content="Copy this answer"
          onClick={copySummary}
        >
          <HiOutlineDocumentDuplicate className="text-blue-1100" />
        </button>
      )}
    </div>
  );
};
