import { HiPlayCircle } from 'react-icons/hi2';
import { SoundWaves } from 'Static/images';
import * as Popover from '@radix-ui/react-popover';
import { AudioPlayer } from 'src/components/AudioPlayer';
import { useState } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { SpokeSpinner } from 'src/components/Spinner';
import { useQuery } from '@tanstack/react-query';
import { fetchConversationRecordingUrl } from 'src/api/conversation';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { FiAlertTriangle } from 'react-icons/fi';

interface ConversationAudioProps {
  containerWidth?: number;
  compressedFilePath: string;
}

export const ConversationAudio: React.FC<ConversationAudioProps> = ({ containerWidth, compressedFilePath }) => {
  const [isErrored, setIsErrored] = useState(false);
  const { isLoading, data, isError, error } = useQuery<string, Error, string, string[]>({
    queryKey: ['fetchPreSignedUrl', compressedFilePath],
    staleTime: 1.5 * 60 * 1000,
    queryFn: () => fetchConversationRecordingUrl(compressedFilePath),
    refetchOnWindowFocus: false,
  });
  return (
    <Popover.Root>
      <Popover.Trigger
        className="relative -mt-1 mr-10 flex h-fit items-center gap-1 overflow-hidden rounded-full border border-indigo-100 bg-indigo-25 p-0.5 hover:border-grey-800 disabled:hover:border-indigo-100"
        disabled={isErrored || isLoading || isError}
        onClick={() => trackFilterEvent(TRACK_EVENT.CONVERSATION_AUDIO_PLAYED)}
        data-tooltip-content={error?.message}
        data-tooltip-id="tooltip"
      >
        {isLoading ? (
          <div className="grid place-items-center rounded-full bg-grey-800 p-2">
            <SpokeSpinner className="text-white" />
          </div>
        ) : (
          <>
            {error ? (
              <span className="flex items-center rounded-full bg-error-700 p-1.5">
                <FiAlertTriangle className="text-normal size-4 text-white" />
              </span>
            ) : (
              <HiPlayCircle className="shrink-0 text-4xl text-grey-800" />
            )}
          </>
        )}
        <SoundWaves />
        <div className="absolute inset-0 left-auto w-8 rounded-r-full bg-gradient-to-r from-transparent to-indigo-25" />
      </Popover.Trigger>
      <Popover.Anchor className="absolute right-3" />
      <Popover.Portal>
        <Popover.Content
          className={cn('z-50 flex gap-3 rounded-xl border border-indigo-100 bg-blue-350 p-3.5 shadow-lg', { 'bg-error-100': isErrored })}
          align="start"
          side="right"
          alignOffset={-5}
          style={{ width: containerWidth + 25 }}
          onInteractOutside={(e) => e.preventDefault()}
        >
          <AudioPlayer autoPlay onError={() => setIsErrored(true)} src={data} />
          <Popover.Close className="h-fit text-base" data-tooltip-id="tooltip" data-tooltip-content="Stop and close">
            <IoCloseCircleOutline />
          </Popover.Close>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
