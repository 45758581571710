import React, { FC, ReactElement, useMemo } from 'react';
import { getDriverData } from 'Utils';
import { Label, LabelList } from 'recharts';
import { DownRedSVG, UpGreenSVG, CustomText } from 'Static/icons';
import { getBarGraphData } from 'Utils/chartsV2/barGraphFunctions';
import { HorizontalBarGraphCard } from 'src/components/Charts/BarCharts/BarGraphCards/HorizontalBarGraph/HorizontalBarGraphCard';
import { currentBar, customXAxis } from 'src/components/Charts/BarCharts/GraphComponents';
import { HorizontalBarGraphContainerProps } from './HorizontalBarGraphContainer';
import { useLDFlags } from 'Hooks/useLaunchDakly';

const CustomLabel = (props): ReactElement => {
  const { x, y, name, width, data } = props;
  const selectedBar = data.find((d) => d.name === name);
  return (
    <g>
      {selectedBar.change < 0 ? (
        <DownRedSVG x={x + width + 15} y={y} color={selectedBar.color} />
      ) : (
        <UpGreenSVG x={x + width + 15} y={y} color={selectedBar.color} />
      )}
      <CustomText x={x + width + 35} y={y + 11} value={selectedBar.dataLabel} color={selectedBar.color} />
    </g>
  );
};

const barData = { barSize: 20, radius: [8, 8, 8, 8] };
export interface IndividualHBGProps extends Omit<HorizontalBarGraphContainerProps, 'tableVisualization' | 'showDateRange' | 'sourceType'> {
  label: string;
}
export const TopIncreaseDecreaseHBG: FC<IndividualHBGProps> = (props) => {
  const { drivers, chartMetricObject, label, onClick, chartComparator, hideLabels, ...rest } = props;
  const tranformDriverDataProps = (data: ReturnType<typeof getDriverData>) => ({
    data: Math.abs(data.change),
    dataLabel: data.changeText,
    color: data.color,
    change: data.change,
  });
  const { surveySourceChangeColor } = useLDFlags();
  const { data, minDomain, maxDomain } = getBarGraphData(
    drivers,
    chartComparator,
    chartMetricObject,
    tranformDriverDataProps,
    undefined,
    props.isSurveyType && surveySourceChangeColor
  );
  const currentCustomLabel = !hideLabels ? <LabelList content={<CustomLabel data={data} />} /> : undefined;

  const xAxisLabel = useMemo(() => <Label value={label} dy={-25} />, [label]);
  const xAxisData = useMemo(
    () => ({
      orientation: 'top' as const,
      axisLine: { stroke: '#E4E7EC' },
      type: 'number' as const,
      domain: [minDomain ?? 0, maxDomain],
      tickFormatter: (value) => (chartComparator === 'percent' ? `${value}%` : value),
    }),
    [minDomain, maxDomain, chartComparator]
  );
  return (
    <HorizontalBarGraphCard
      data={data}
      selectedMetricType={chartMetricObject.name}
      chartComparator={chartComparator}
      onClick={onClick}
      isSurveyType={props.isSurveyType}
    >
      {customXAxis({ xAxisData, xAxisLabel })}
      {currentBar({ currentCustomLabel, onClick, barData })}
    </HorizontalBarGraphCard>
  );
};
