import React, { FC } from 'react';
import { HiOutlineSparkles } from 'react-icons/hi2';
import { QuickSummaryResult } from './QuickSummaryResult';
import { useQuickSummaryDiginContext } from '../QuickSummaryDigInContext';
import { SummaryLoader } from './SummaryLoader';
import { FailedToGenerateQuickSummary } from './FailedToGenerateQuickSummary';
import { useQuickInsight } from '../QuickInsightContainer';
import { useAppSelector } from 'src/core/store';
import { cn } from 'Utils/TailwindUtils';
import { QuickInsightBreadCrumb } from '../Conversations/QuickInsightBreadCrumb';
import { useSearchParams } from 'react-router-dom';

interface QuickSummaryProps {
  navigateToDiscover: () => void;
  timer: number;
}

export const QuickSummary: FC<QuickSummaryProps> = ({ navigateToDiscover, timer }) => {
  const [{ summaryStatus }] = useQuickSummaryDiginContext();
  const { selectedDimension } = useQuickInsight();
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const [searchParams] = useSearchParams();
  return (
    <>
      {(selectedDrillDownTopic || searchParams.get('aspect')) && <QuickInsightBreadCrumb selectedDimension={selectedDimension} isDigin />}
      <div
        className={cn('mr-5 flex h-[91%] gap-2.5 overflow-y-auto rounded-xl bg-indigo-25 px-4', {
          'h-[86%]': selectedDrillDownTopic || searchParams.get('aspect'),
        })}
      >
        <div className="mt-11">
          <span className="flex items-center justify-center rounded-full bg-indigo-600 px-2.5 py-2.5">
            <HiOutlineSparkles className="fill-white text-white" />
          </span>
        </div>
        {summaryStatus === 'pending' && <SummaryLoader timer={timer} />}
        {summaryStatus === 'fulfilled' && <QuickSummaryResult navigateToDiscover={navigateToDiscover} />}
        {summaryStatus === 'rejected' && <FailedToGenerateQuickSummary />}
      </div>
    </>
  );
};
