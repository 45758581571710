import { cn } from 'Utils/TailwindUtils';
import React, { FC } from 'react';
import remarkGfm from 'remark-gfm';
import MarkDownBase from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

export const Markdown: FC<ReactMarkdownOptions> = ({ children, className, ...rest }) => {
  return (
    <MarkDownBase
      remarkPlugins={[remarkGfm]}
      className={cn(
        'prose prose-sm text-sm text-gray-510',
        'prose-headings:text-blue-1100 prose-h1:border-b prose-h1:pb-2 prose-h1:text-3xl prose-h1:font-bold',
        'prose-h2:my-2.5 prose-h2:border-b prose-h2:pb-2 prose-h2:text-2xl prose-h2:font-bold',
        'prose-h3:my-2 prose-h3:text-xl prose-h3:font-semibold',
        'prose-ol:ml-3 prose-ol:list-outside prose-ol:list-decimal prose-ol:leading-3',
        'prose-li:ml-3 prose-li:list-outside prose-li:list-disc prose-li:leading-3',
        'prose-li:leading-normal',
        'prose-th:font-bold',
        className
      )}
      {...rest}
    >
      {children}
    </MarkDownBase>
  );
};
