import { Box, Text } from 'grommet';
import { filter } from 'lodash';

import SentimentTag from './SentimentTag';

const TagsCount = ({ count }) => (
  <Box>
    <Text size="xsmall" color="dark-6">{`+${count}`}</Text>
  </Box>
);

const Tags = ({ tags, type }) => {
  const positive = filter(tags, ['sentiment', 'positive']);
  const negative = filter(tags, ['sentiment', 'negative']);
  const neutral = filter(tags, ['sentiment', 'neutral']);
  const orderedTags = negative.concat(positive).concat(neutral);

  return orderedTags.length > 2 ? (
    <Box wrap direction="row" gap="xsmall">
      <SentimentTag
        name={orderedTags[0].name}
        sentiment={type === 'support' ? 'sentiment' : orderedTags[0].sentiment}
        key={0}
        margin={{ bottom: 'xsmall' }}
      />
      <SentimentTag
        name={orderedTags[1].name}
        sentiment={type === 'support' ? 'sentiment' : orderedTags[1].sentiment}
        key={1}
        margin={{ bottom: 'xsmall' }}
      />
      <TagsCount count={orderedTags.length - 2} />
    </Box>
  ) : (
    <Box wrap direction="row" gap="xsmall">
      {orderedTags.map((tag, index) => (
        <SentimentTag name={tag.name} sentiment={type === 'support' ? 'sentiment' : tag.sentiment} key={index} margin={{ bottom: 'xsmall' }} />
      ))}
    </Box>
  );
};

export default Tags;
