import React, { FC, useEffect, useState } from 'react';
import { RelevantDriverCarousel } from './RelevantDriverCarousel';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { RelevantDriversData } from './RelevantDriverData';
import { CommonPhrasesTab } from './CommonPhrasesTab';
import { getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { useSearchParams } from 'react-router-dom';
import { useDrillDownContext } from 'src/ui/containers/QuickInsight/DrillDown';
import { cn } from 'Utils/TailwindUtils';
import { updateDIYTopicKeywords } from 'Slices/analytics';

interface RelevantDriverProps {
  totalConvCount?: number;
  className?: string;
}

export const RelevantDriver: FC<RelevantDriverProps> = ({ totalConvCount, className }) => {
  const {
    dimension,
    selectedTopic,
    chartDimensionObject,
    setDrillDownContext,
    selectedClientSourceData,
    chartMetric,
    chartComparator,
    selectedTheme,
  } = useDrillDownContext();
  const [theme, setTheme] = useState(selectedTheme);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const isThemeExpanded = searchParams.get('theme-expanded') === 'true';
  const overviewDataForSelectedTheme = useAppSelector((state) => state.summary.summaryGraphDataV2?.[chartMetric]);
  const { topics } = useAppSelector((state) => state.analytics);
  const item = isThemeExpanded ? overviewDataForSelectedTheme : topics?.find((topic) => topic.name === selectedTopic);
  const { selectedDrillDownTopic } = useAppSelector((state) => state.filter);
  const convCount =
    totalConvCount !== undefined ? totalConvCount : getMetricValueAndPercentChange(item?.current_interval?.metrics, 'volume', chartComparator)?.value;

  useEffect(() => {
    searchParams.get('aspect') && setDrillDownContext({ dimension: { name: 'common_phrases', display_name: 'Common Phrases', path: '' } });
  }, [searchParams.get('aspect')]);

  useEffect(() => {
    if (dimension.name === 'diy_topic' && selectedDrillDownTopic) {
      dispatch(
        updateDIYTopicKeywords(
          selectedClientSourceData?.es_alias?.custom_topics?.find((data) => data.name === selectedDrillDownTopic?.topic)?.keywords ?? []
        )
      );
    } else if (chartDimensionObject.name !== 'diy_topic') dispatch(updateDIYTopicKeywords([]));
  }, [dimension, selectedDrillDownTopic]);
  return (
    <>
      <div className="flex gap-1 text-sm font-bold text-gray-500">Breakdown of {convCount?.toLocaleString()} conversations with Volume</div>
      <div className={cn('rounded-xl shadow', className)}>
        <RelevantDriverCarousel setTheme={setTheme} />
        {dimension.name === 'common_phrases' ? (
          <CommonPhrasesTab chartDimensionObject={chartDimensionObject} selectedTopic={selectedTopic} chartComparator={chartComparator} />
        ) : (
          <RelevantDriversData theme={theme} setTheme={setTheme} />
        )}
      </div>
    </>
  );
};
