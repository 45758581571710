import React, { useCallback } from 'react';
import { useAppSelector } from 'src/core/store';
import { FiLogOut, FiSettings } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { Avatar } from 'src/components/Avatar';
import { HiBuildingOffice, HiOutlineLifebuoy } from 'react-icons/hi2';
import { logout } from 'Utils/auth';
import { TRACK_EVENT, trackEvent } from 'src/core/trackEvent';

export const UserProfile = () => {
  const user = useAppSelector((state) => state.auth.user);
  const clientsLength = user?.clients?.length;
  const HelpCenterClick = useCallback(() => {
    trackEvent(TRACK_EVENT.OPEN_HELP_CENTER);
    window.open('https://help.sentisum.com', '_blank');
  }, []);
  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild className="transition-transform radix-state-open:scale-90 hover:scale-90">
        <Avatar name={user?.name} type="button" />
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Content side="right" align="end" sideOffset={18} className="radix-dropdown-content rounded-xl border ">
        {user?.clients?.length > 0 && (
          <div className="flex items-center gap-6 rounded-t-xl bg-grey-50 px-8 py-2.5 text-xs capitalize text-grey-500">
            <HiBuildingOffice className="text-base" />
            {user.clients[0].display_name}
          </div>
        )}
        <div className="space-y-3 divide-y">
          <div className="flex items-center gap-4 px-5 pt-2">
            <Avatar name={user?.name} />
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-grey-800">{user?.name}</span>
              <span className="text-xs text-grey-500">{user?.email}</span>
            </div>
          </div>
          <div className="p-2">
            <button
              className="flex w-full items-center gap-3 rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000"
              onClick={HelpCenterClick}
            >
              <HiOutlineLifebuoy size={18} />
              <span>Help Guide</span>
            </button>
            {clientsLength > 0 && (
              <NavLink to="/settings" className="flex items-center gap-3 rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000">
                <FiSettings size={18} />
                <span>Settings</span>
              </NavLink>
            )}
            <button className="flex w-full items-center gap-3 rounded-md p-2 text-left text-sm text-red-900 hover:bg-sky-1000" onClick={logout}>
              <FiLogOut size={18} />
              <span>Log out</span>
            </button>
          </div>
        </div>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Root>
  );
};
