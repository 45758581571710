import { getDriverData } from 'Utils';
import React, { FC } from 'react';
import { VerticalBarGraphSection } from 'src/components/Charts/BarCharts/BarGraphCards/VerticalBarGraph/VerticalBarGraphSection';
import { VerticalBarGraphContainerProps } from './VerticalBarGraphContainer';
import { getBarGraphData } from 'Utils/chartsV2/barGraphFunctions';
import { Topic } from 'src/api/apiTypes/Summary';
import { useLDFlags } from 'Hooks/useLaunchDakly';

export interface IndividualVBGProps extends Omit<VerticalBarGraphContainerProps, 'tableVisualization'> {
  label: string;
}

export const HighestLowestVBG: FC<IndividualVBGProps> = (props) => {
  const { drivers, chartMetricObject, highestAndlowestValue, showDateRange, chartComparator, ...rest } = props;
  const tranformDriverDataProps = (c: ReturnType<typeof getDriverData>) => ({ dataLabel: c.valueText });
  const transformDataValueProps = (topic: Topic) => ({
    data: topic.current_interval.metrics[0].value,
    previousData: topic.previous_interval.metrics[0].value,
  });
  const { surveySourceChangeColor } = useLDFlags();
  const { data, ...restData } = getBarGraphData(
    drivers,
    chartComparator,
    chartMetricObject,
    tranformDriverDataProps,
    transformDataValueProps,
    surveySourceChangeColor && props.isSurveyType
  );
  const dateRange = { dateRange: drivers[0].current_interval.range, previousDateRange: drivers[0].previous_interval.range };
  return (
    <VerticalBarGraphSection
      data={data}
      domain={restData}
      dateRange={showDateRange ? dateRange : undefined}
      selectedMetricType={chartMetricObject.name}
      chartComparator={chartComparator}
      {...rest}
    />
  );
};
