import { SURVEY_TYPES, SUPPORT_TYPES, TIME_TYPES } from 'Enums/SourceModelTypes';

const isSupportType = (type) => {
  return SUPPORT_TYPES.includes(type);
};

const isSurveyType = (type) => {
  return SURVEY_TYPES.includes(type);
};

const isTimeType = (type) => {
  return TIME_TYPES.includes(type);
};

const getChartValueDisplayProperty = (value, metaddata, absoluteValue) => {
  let prefixText = '';
  let postfixText = '';
  if (typeof value === 'number') {
    if (metaddata?.IS_ROUND_OFF) {
      value = Math.round(value);
    }
    if (metaddata?.IS_ABSOLUTE || absoluteValue) {
      value = Math.abs(value);
    } else if (metaddata?.IS_ADD_SYMBOL_NEED) {
      prefixText = value > 0 ? '+' : '';
    }
    if (metaddata?.IS_PERCENT_SYMBOL_NEED) {
      postfixText = '%';
    }
    if (metaddata?.IS_LOCALE_STRING) {
      value = Number?.convertToLocaleString(value);
    }
    if (metaddata?.CONVERT_MINS_TO_HOURS) {
      if (typeof value === typeof '') value = value.replace(/,/g, '');
      const time_min = parseInt(value);
      const hours = Math.floor(time_min / 60);
      const mins = time_min % 60;

      value = '';
      if (hours !== 0) value = `${hours}h`;
      if (mins !== 0) value += ` ${mins}m`;
      if (hours === 0 && mins === 0) value = '0m';
      value = value.trim();
    }
    if (metaddata?.IS_SECONDS) {
      if (typeof value === typeof '') value = value.replace(/,/g, '');
      const time_sec = parseInt(value);
      if (time_sec !== 0) value = `${time_sec} sec`;
      else value = '0s';
      value = value.trim();
    }
    if (metaddata?.CONVERT_SECS_TO_MINS) {
      if (typeof value === 'string') value = value.replace(/,/g, '');
      const time_sec = parseInt(value);
      const time_min = Math.floor(time_sec / 60);
      const secs = time_sec % 60;

      value = '';
      if (time_min !== 0) value = `${time_min}min`;
      if (secs !== 0) value += ` ${secs}s`;
      if (time_min === 0 && secs === 0) value = '0s';
      value = value.trim();
    }
    if (metaddata?.IS_MINUTES) {
      if (typeof value === typeof '') value = value.replace(/,/g, '');
      const time_min = parseInt(value);
      if (time_min !== 0) value = `${time_min} min`;
      else value = '0m';
      value = value.trim();
    }
    return { prefixText, value, postfixText };
  }
  return { prefixText, value: 0, postfixText };
};
export default {
  isSupportType,
  isSurveyType,
  isTimeType,
  getChartValueDisplayProperty,
};
