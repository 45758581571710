import { useEffect, useMemo, useState } from 'react';
import { filter, cloneDeep, isEqual } from 'lodash';

import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { updateTags } from 'Slices/timeseries';
import { TabbedMenu } from 'src/components/Dropdown/DropdownMenu';
import { HiOutlineFaceFrown, HiOutlineFaceSmile } from 'react-icons/hi2';
import { Neutral } from 'Static/images';
import { casex } from 'casex';
import { Button } from 'src/components/Button';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import EditableSentimentTag from 'Components/EditableSentimentTag';
import { useInboxContext } from 'src/ui/containers/QuickInsight/Conversations';

const Tags = ({ id, type, allTags, tags = [] }) => {
  const [updatedTags, setUpdatedTags] = useState(cloneDeep(tags));
  const [availableTags, setAvailableTags] = useState(null);
  const [isTagsChanged, setIsTagsChanged] = useState(false);
  const { source } = useInboxContext();
  const dispatch = useAppDispatch();
  const positive = filter(updatedTags, ['sentiment', 'positive']);
  const negative = filter(updatedTags, ['sentiment', 'negative']);
  const neutral = filter(updatedTags, ['sentiment', 'neutral']);
  const orderedTags = negative.concat(positive).concat(neutral);
  const [addTagDropdownOpen, setAddTagDropdownOpen] = useState(false);

  const formatTags = (tags) =>
    tags?.map(({ name, sentiment }) => ({ topic: name, sentiment: sentiment === 'positive' ? 3 : sentiment === 'neutral' ? 2 : 1 }));
  const saveTags = () => {
    const tags = formatTags(updatedTags);
    dispatch(updateTags({ source, conversationId: id, updatedTopics: tags }));
    trackFilterEvent(TRACK_EVENT.CONVERSATION_QA_TAG_UPDATE, { Id: id, tags: tags });
  };

  const handleAction = (name: string, action: string) => {
    let newTags = cloneDeep(updatedTags);
    if (!newTags.some((tag) => tag.name === name)) newTags.push({ name, sentiment: action });
    else if (action === 'remove') newTags = newTags.filter((tag) => name !== tag.name);
    else newTags.find((tag) => name === tag.name).sentiment = action;
    setUpdatedTags(newTags);
    setAddTagDropdownOpen(false);
  };

  useEffect(() => {
    setUpdatedTags(tags);
  }, [id]);

  useEffect(() => {
    // update availableTags whenever updatedTags is updated
    setAvailableTags(allTags.filter((tag) => !updatedTags.map((tag) => tag.name).includes(tag)));
    setAvailableTags(allTags.filter((tag) => !updatedTags.map((tag) => tag.name).includes(tag)));

    // check if tags have changed
    if (isEqual(tags.sort(), updatedTags.sort())) setIsTagsChanged(false);
    else setIsTagsChanged(true);
  }, [tags, updatedTags, isTagsChanged]);

  const allAvailableTags = useMemo(
    () => [
      {
        label: 'Add Tags',
        options: availableTags?.map((label) => ({
          label: casex({ text: label, pattern: 'Ca Se' }),
          value: label,
          ...(type === 'survey' && {
            extra: [
              {
                title: 'Positive',
                value: 'poitive',
                icon: HiOutlineFaceSmile,
                iconProps: { className: 'text-green-600 w-5 h-5 cursor-pointer' },
                onClick: (value: string) => handleAction(value, 'positive'),
              },
              {
                title: 'Neutral',
                value: 'neutral',
                icon: Neutral,
                iconProps: { className: 'w-5 h-5 cursor-pointer' },
                onClick: (value: string) => handleAction(value, 'neutral'),
              },
              {
                title: 'Negative',
                value: 'negative',
                icon: HiOutlineFaceFrown,
                iconProps: { className: 'text-red-600 w-5 h-5 cursor-pointer' },
                onClick: (value: string) => handleAction(value, 'negative'),
              },
            ],
          }),
        })),
      },
    ],
    [availableTags]
  );
  return (
    <div className="flex flex-wrap gap-1.5">
      {orderedTags.map((tag, index) => (
        <EditableSentimentTag {...tag} type={type} key={index} onAction={handleAction} />
      ))}
      <DropdownPrimitive.Root open={addTagDropdownOpen} onOpenChange={setAddTagDropdownOpen}>
        <DropdownPrimitive.Trigger asChild>
          <Button variant="secondary" className="rounded-full py-0.5" label="+ Add" />
        </DropdownPrimitive.Trigger>
        <DropdownPrimitive.Content align="end" sideOffset={5} side="bottom" className="z-50 rounded-md bg-white drop-shadow-xl">
          <TabbedMenu
            enableSearch
            noDataMessage="No Data"
            searchPlaceholder="Search for tag"
            options={allAvailableTags}
            type={type}
            onChange={type === 'support' ? (value: string) => handleAction(value, 'neutral') : null}
          />
        </DropdownPrimitive.Content>
      </DropdownPrimitive.Root>
      {isTagsChanged && <Button variant="primary" label="Save" className="rounded-full py-0.5" onClick={saveTags} />}
    </div>
  );
};

export default Tags;
