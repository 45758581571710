import React from 'react';
import { DownRed, UpGreen } from 'Static/images';
import { checkConditions, getColorOverDataChange } from 'Utils';
import { getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'Utils/enums/ChartMetadata';
import { FC, createElement } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSummaryOverviewGraphData } from 'src/api/Summary';
import { useQuickInsight } from '../QuickInsightContainer';
import { cn } from 'Utils/TailwindUtils';
import { MetricCardLoader } from './MetricCardLoader';
import { Metric } from 'src/types/User';
import { TRACK_EVENT, trackDashboardEvent } from 'src/core/trackEvent';
import { useLDFlags } from 'Hooks/useLaunchDakly';

export const MetricItem: FC<{ metric: Metric; setSelectedMetric: (metric) => void; isSurveyType: boolean }> = ({
  metric,
  setSelectedMetric,
  isSurveyType,
}) => {
  const { selectedMetric, selectedDimension, topicPayload, state, chartComparator, dimension } = useQuickInsight();
  let filterDimension = {};
  if (topicPayload.hasOwnProperty('dimension_values')) {
    filterDimension = {
      filter_dimension: {
        filter_value: topicPayload.dimension_values,
        dimension: dimension,
      },
    };
  }
  let updatedPayload = topicPayload.hasOwnProperty('dimension_values') ? filterDimension : topicPayload;
  const useMetricQuery = useQuery({
    queryKey: ['metric', metric?.name, state, selectedDimension],
    queryFn: () => getSummaryOverviewGraphData({ ...updatedPayload, metric }, state, false),
  });
  const { surveySourceChangeColor } = useLDFlags();
  if (useMetricQuery.isLoading) return <MetricCardLoader />;
  const metricData = useMetricQuery?.data?.data?.[metric?.name]?.current_interval?.metrics[0];
  const metricValueAndChange = getMetricValueAndPercentChange(metricData, metricData?.metric_type, chartComparator);
  const metricMetadata = METRICS_VALUE_META[metricData?.metric_type];
  const getCurrentValue = () => {
    let { prefixText, value: valueData } = checkConditions.getChartValueDisplayProperty(metricData?.value, metricMetadata);
    return prefixText + valueData;
  };
  const percentChangeMetadata = PERCENT_CHANGE_COMPARATOR_META[chartComparator][metricData?.metric_type];
  const getPercentChangedText = () => {
    const { value, postfixText } = checkConditions.getChartValueDisplayProperty(metricValueAndChange?.change, percentChangeMetadata, true);
    return value + postfixText;
  };
  const color = getColorOverDataChange(metricData?.metric_type, metricValueAndChange?.change, isSurveyType && surveySourceChangeColor) as string;
  const changeSelectedMetric = () => {
    trackDashboardEvent(TRACK_EVENT.QUICK_INSIGHT_METRIC_SELECT, { metricName: metric.name });
    setSelectedMetric(metric);
  };
  return (
    <div
      className={cn('mt-1 flex min-w-48 flex-col justify-between space-y-1 overflow-x-auto rounded-xl p-3', {
        'bg-indigo-50': selectedMetric?.name === metric.name,
      })}
      onClick={changeSelectedMetric}
      role="button"
      data-tooltip-id="tooltip"
      data-tooltip-content={metric.display_name}
      data-tooltip-hidden={metric.display_name.length < 20}
    >
      <span className="line-clamp-1 text-sm font-semibold uppercase text-gray-500">{metric.display_name}</span>
      <div className="flex items-center gap-2">
        <p className="flex items-center text-2xl font-bold">
          {getCurrentValue()} {metricMetadata?.IS_PERCENT_SYMBOL_NEED ? <span className="pl-1 text-lg font-normal">%</span> : null}
        </p>
        <div className="flex gap-1.5" style={{ color: color }}>
          <span className="flex items-center justify-center">
            {createElement(metricValueAndChange?.change < 0 ? DownRed : UpGreen, { stroke: color, fill: color })}
          </span>
          {getPercentChangedText()}
        </div>
      </div>
    </div>
  );
};
