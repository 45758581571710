import theme from 'src/ui/theme';
import { ADMIN_INDEX_SOURCE_TYPES, SOURCE_TYPES, SURVEY_TYPES, SURVEY_TYPES_DASHBOARD, SourceModelTypesEnum } from 'Enums/SourceModelTypes';
import { Topic, ISummaryDataResponse } from 'src/api/apiTypes/Summary';
import { SummaryTableVisualization } from 'src/types/enums';
import { IChartComparator, IChartIntervalBreakDown } from 'src/types/Filter';
import { getMetricValueAndPercentChange } from './chartsV2/graphFunctions';
import checkConditions from './checkConditions';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'Enums/ChartMetadata';
import { casex } from 'casex';
import saveAs from 'file-saver';
import { toCSV } from 'react-csv/lib/core';
import { LANG_TYPES } from './constants/languages';
import TEXT_TYPES from 'Constants/textTypes';
import { CHART_COMPARATOR_TYPES_ENUM } from 'Enums/ChartComparatorTypes';
import { Dimension, Metric } from 'src/types/User';
import { SURVEY_DRIVERS_HEADER_NAME } from 'Enums/SummaryDriverTypes';
import { IValueOverTime } from 'src/components/Charts/LineCharts/types';

export const getColorOverDataChange = (selectedSummaryType: string, value: number, isSurveyType?: boolean) => {
  let colorType = 'neutral';
  const percentChange = value;
  if (percentChange === 0) colorType = 'neutral';
  else if (SURVEY_TYPES.includes(selectedSummaryType as ADMIN_INDEX_SOURCE_TYPES)) colorType = percentChange > 0 ? 'positive' : 'negative';
  else if (isSurveyType && SURVEY_TYPES_DASHBOARD.includes(selectedSummaryType as SOURCE_TYPES))
    colorType = percentChange > 0 ? 'positive' : 'negative';
  else colorType = percentChange > 0 ? 'negative' : 'positive';
  return theme.global.colors[colorType] as string;
};

export const driversByReportType = (data: ISummaryDataResponse, type: SummaryTableVisualization, comparator: IChartComparator) => {
  if (!data) return null;
  const cardDriversByComparator = data.card_drivers?.find((cardDriver) => cardDriver.change === comparator);
  switch (type) {
    case SummaryTableVisualization.Highest:
      return data?.drivers;
    case SummaryTableVisualization.Lowest:
      return [...data?.drivers].reverse();
    case SummaryTableVisualization.TopNegative:
    case SummaryTableVisualization.TopIncrease:
      return cardDriversByComparator?.top_increases.map((driver) => data.drivers.find(({ name }) => name === driver));
    case SummaryTableVisualization.TopPositive:
    case SummaryTableVisualization.TopDecrease:
      return cardDriversByComparator?.top_decreases.map((driver) => data.drivers.find(({ name }) => name === driver));
    case SummaryTableVisualization.BiggestChanges:
      return data?.biggestChanges
        ?.find((biggestChange) => biggestChange.change === comparator)
        ?.driverName.map((driver) => data.drivers?.find(({ name }) => name === driver));
    default:
      return null;
  }
};

export const reportDriversByReportType = (data: ISummaryDataResponse, type: SummaryTableVisualization, comparator: IChartComparator) => {
  const drivers = driversByReportType(data, type, comparator);
  if (type === SummaryTableVisualization.Lowest) return drivers?.reverse();
  return drivers;
};

export const getDriverData = (
  driver: Topic,
  comparator: IChartComparator,
  metric: SOURCE_TYPES,
  absoluteChangeText: boolean = false,
  isSurveyType?: boolean
) => {
  const { value, change } = getMetricValueAndPercentChange(driver.current_interval.metrics, metric, comparator);
  const valueText = checkConditions.getChartValueDisplayProperty(value, METRICS_VALUE_META[metric]);
  const changeText = checkConditions.getChartValueDisplayProperty(change, PERCENT_CHANGE_COMPARATOR_META[comparator][metric], absoluteChangeText);
  const color = getColorOverDataChange(metric, change, isSurveyType) as string;
  const noOfResponses = getMetricValueAndPercentChange(driver.current_interval.metrics, SOURCE_TYPES.SUPPORT_TYPE, comparator)?.value;
  return { valueText: Object.values(valueText).join(''), changeText: Object.values(changeText).join(''), color, noOfResponses, change };
};

export const downloadDriversAsCSV = (
  sourceType: ADMIN_INDEX_SOURCE_TYPES,
  metric: Metric,
  drivers: Topic[],
  comparator: IChartComparator,
  dimension: Dimension,
  name: string
) => {
  const isSurveyType = SURVEY_TYPES.includes(sourceType);
  const showNoOfResponses = SOURCE_TYPES.SURVEY_TYPE === metric.name;
  const driversData = drivers.map((driver) => {
    const { changeText, valueText, noOfResponses } = getDriverData(driver, comparator, metric.name);
    return { name: casex({ text: driver.name, pattern: 'Ca Se' }), valueText, changeText, noOfResponses: showNoOfResponses ? noOfResponses : null };
  });
  const headers = [
    { label: casex({ text: dimension.display_name, pattern: 'Ca Se' }), key: 'name' },
    { label: casex({ text: isSurveyType ? SURVEY_DRIVERS_HEADER_NAME[metric.name] : metric.display_name, pattern: 'Ca Se' }), key: 'valueText' },
    { label: casex({ text: CHART_COMPARATOR_TYPES_ENUM[comparator][LANG_TYPES.EN][TEXT_TYPES.LONG], pattern: 'Ca Se' }), key: 'changeText' },
  ];
  if (SOURCE_TYPES.SURVEY_TYPE === metric.name) headers.push({ label: 'No. of Responses', key: 'noOfResponses' });
  const csvBlob = new Blob([toCSV(driversData, headers, ',', `"`)], { type: 'text/csv;charset=utf-8;' });
  saveAs(csvBlob, `${name}.csv`);
};

export const downloadOverTimeAsCSV = (
  data: IValueOverTime,
  metricName: string,
  comparator: IChartComparator,
  breakDown: IChartIntervalBreakDown,
  name: string
) => {
  const isEndDateAvailable = !['day', 'hour'].includes(breakDown);
  const metricLabel = casex({ text: SourceModelTypesEnum[metricName]?.[LANG_TYPES.EN][TEXT_TYPES.ALIAS], pattern: 'Ca Se' });
  const changeLabel = `${CHART_COMPARATOR_TYPES_ENUM[comparator][LANG_TYPES.EN][TEXT_TYPES.ALIAS]} ${metricLabel} Change`;
  const headers = [
    { label: isEndDateAvailable ? 'Selected Start Date' : 'Selected Date', key: 'dataMeta.range.start' },
    { label: isEndDateAvailable ? 'Previous Start Date' : 'Previous Date', key: 'previousDataMeta.range.start' },
    { label: metricLabel, key: `dataMeta.metric.value` },
    { label: `Previous ${metricLabel}`, key: `previousDataMeta.metric.value` },
    { label: changeLabel, key: `dataMeta.metric.change` },
  ];
  if (isEndDateAvailable) {
    headers.splice(1, 0, { label: 'Selected End Date', key: 'dataMeta.range.end' });
    headers.splice(3, 0, { label: 'Previous End Date', key: 'previousDataMeta.range.end' });
  }
  const csvBlob = new Blob([toCSV(data.data, headers, ',', `"`)], { type: 'text/csv;charset=utf-8;' });
  saveAs(csvBlob, `${name}.csv`);
};
