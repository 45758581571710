import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startToDownloadDataExport } from 'src/api/Analytics';
import { store } from '../store';

const initialStateAPIStatus = {
  dataExportDownloadingStatus: 'idle',
};

const initialState = {
  ...initialStateAPIStatus,
  dataExportType: null,
  dataExportStep: 0,
  showExportPopup: false,
  startDownload: null,
};

export const startToDownloadDataExportFromServerV3 = createAsyncThunk<any, any>(
  'startToDownloadDataExportFromServerV3',
  async ({
    theme,
    chartComparator,
    topic,
    diy_topic,
    search,
    aspect,
    exportProps,
    filters,
    selectedSourceClientData,
    sentiment,
    dimension_values,
  }) => {
    if (store) {
      const storeData = store.getState();
      const chartDimensionObject = storeData?.filter?.chartDimensionObject;
      const chartMetricObject = storeData?.filter?.chartMetricObject;
      return startToDownloadDataExport(
        {
          chartComparator,
          search,
          theme,
          topic,
          diy_topic,
          aspect,
          exportProps,
          sentiment,
          dimension_values,
          state: {
            chartDimensionObject,
            chartMetricObject,
            chartIntervalBreakdown: storeData?.filter?.chartIntervalBreakdown,
            selectedSourceClientData,
            typeMetric: storeData?.filter?.typeMetric,
            filters: filters,
          },
        },
        { selectedSourceClientData, chartDimensionObject, chartMetricObject }
      );
    }
  }
);

const DataExportSlice = createSlice({
  name: 'dataexport',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetDataExportReduxState: (state) => {
      Object.assign(state, { ...initialState });
    },
    setExportDataModelType: (state, action) => {
      state.dataExportStep = state.dataExportStep + 1;
      state.dataExportType = action.payload.type;
    },
    toggleShowExportPopup: (state, action) => {
      if (!action.payload) {
        state.startDownload = null;
      }
      state.showExportPopup = action.payload;
    },
    startToDownloadExportData: (state) => {
      const download = new String('download');
      state.startDownload = download.toString();
    },
    resetDataExportDownloadingStatus: (state) => {
      state.dataExportDownloadingStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startToDownloadDataExportFromServerV3.pending, (state, action) => {
        state.dataExportDownloadingStatus = 'pending';
      })
      .addCase(startToDownloadDataExportFromServerV3.fulfilled, (state, action) => {
        state.dataExportDownloadingStatus = 'fulfilled';
      })
      .addCase(startToDownloadDataExportFromServerV3.rejected, (state, action) => {
        state.dataExportDownloadingStatus = 'rejected';
      });
  },
});

export const {
  resetDataExportReduxState,
  setExportDataModelType,
  toggleShowExportPopup,
  startToDownloadExportData,
  resetDataExportDownloadingStatus,
} = DataExportSlice.actions;
export default DataExportSlice.reducer;
