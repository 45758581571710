import React, { ComponentProps, FC } from 'react';
import AudioPlayerBase, { RHAP_UI } from 'react-h5-audio-player';
import { RewindTen, ForwardTen } from 'Static/images';
import { HiPlayCircle, HiPauseCircle } from 'react-icons/hi2';
import 'react-h5-audio-player/lib/styles.css';
import './styles.css';

export const AudioPlayer: FC<ComponentProps<typeof AudioPlayerBase>> = (props) => (
  <AudioPlayerBase
    customVolumeControls={[]}
    customAdditionalControls={[]}
    customIcons={{ rewind: <RewindTen />, forward: <ForwardTen />, play: <HiPlayCircle />, pause: <HiPauseCircle /> }}
    customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, <div>/</div>, RHAP_UI.DURATION]}
    showDownloadProgress={false}
    progressJumpSteps={{ backward: 10000, forward: 10000 }}
    {...props}
  />
);
