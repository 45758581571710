import { cn } from 'Utils/TailwindUtils';
import React, { FC, PropsWithChildren } from 'react';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useDrillDownContext } from 'src/ui/containers/QuickInsight/DrillDown';

interface RelevantDriverContainerProps {
  driversLength?: number;
  showAll: boolean;
  setShowAll: (showAll: boolean) => void;
}

export const RelevantDriverContainer: FC<PropsWithChildren<RelevantDriverContainerProps>> = ({ driversLength, children, showAll, setShowAll }) => {
  const { dimension } = useDrillDownContext();
  const handleToggleShow = () => {
    setShowAll(!showAll);
    trackFilterEvent(TRACK_EVENT.DRILL_DOWN_EXAND_TOGGLED, { dimension: dimension.name, expanded: showAll });
  };
  return (
    <div
      className={cn('relative flex min-h-[12rem] flex-col rounded-b-xl border border-t-0 px-5 py-3 text-sm font-medium', {
        'max-h-80 overflow-auto': driversLength > 5,
      })}
    >
      <span>{children}</span>
      {driversLength > 5 && (
        <div className="mt-auto border-t border-dashed text-center text-xs font-medium text-gray-400">
          <button className="px-3 pt-2" onClick={handleToggleShow}>
            {showAll ? 'Show less' : 'Load more'}
          </button>
        </div>
      )}
    </div>
  );
};
