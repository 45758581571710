import React, { useEffect } from 'react';
import { useStompClient } from 'react-stomp-hooks';
import { useTween } from 'react-use';
import { useQuickSummaryDiginContext } from './QuickSummaryDigInContext';
import { TRACK_EVENT, trackDashboardEvent } from 'src/core/trackEvent';

interface TimerProps {
  setTimer: React.Dispatch<React.SetStateAction<number>>;
}
export const Timer: React.FC<TimerProps> = ({ setTimer }) => {
  const client = useStompClient();
  const timer = useTween('linear', (Number(process.env.REACT_APP_DIGIN_TIME) + 30) * 1000) * 100;
  const [_, setDigInState] = useQuickSummaryDiginContext();
  useEffect(() => void setTimer(timer), [timer, setTimer]);

  if (timer === 100) {
    setDigInState({ summaryStatus: 'rejected', summaryError: 'Dig in timed out' });
    trackDashboardEvent(TRACK_EVENT.QUICK_SUMMARY_FAILED, { error: 'Dig in timed out' });
    client?.unsubscribe('/user/topic/qna');
  }
  return <></>;
};
